import axios from "axios";
import { getDefaultConfigHeader } from "../../Utils/headerDefaultConfigPetition";

export const getAllPercentageByYear = (year: number) => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/objectives/percentage/" + year,
    getDefaultConfigHeader()
  );
};

export const removePercentage = (id: number) => {
  return axios.delete(
    process.env.REACT_APP_API_BACKEND +
      "/objectives/percentage/delete?id_porcentaje=" +
      id,
    getDefaultConfigHeader()
  );
};

export const createPercentageNextYear = (porcentage: number) => {
  return axios.post(
    process.env.REACT_APP_API_BACKEND +
      "/objectives/percentage/nextYear?porcentaje=" +
      porcentage,
    {},
    getDefaultConfigHeader()
  );
};

export const updatePercentage = (config: any) => {
  return axios.post(
    process.env.REACT_APP_API_BACKEND + "/objectives/percentage/update",
    config,
    getDefaultConfigHeader()
  );
};
