const initialState = {
  team: [],
  isManager: false,
};

export const teamReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case "@team/set":
      return {
        ...state,
        team: action.payload.team,
        isManager: action.payload.isManager,
      };
    default:
      return state;
  }
};
