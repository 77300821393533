import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import { getDefaultConfigHeader } from "../../Utils/headerDefaultConfigPetition";

export const getAllPositions = () => {
  return axios
    .get(
      process.env.REACT_APP_API_BACKEND + "/position",
      getDefaultConfigHeader()
    )
    .catch((error) => toast.error("Ha habido un error"));
};

export const getCurrentPositionByID = (id_position: any) => {
  return axios
    .get(
      process.env.REACT_APP_API_BACKEND + "/position/" + id_position,
      getDefaultConfigHeader()
    )
    .catch((error) => toast.error("Ha habido un error"));
};

export const getCurrentPositionConfig = () => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/position/config/yearAct",
    getDefaultConfigHeader()
  );
};

export const getNextPositionConfig = () => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/position/config/yearSig",
    getDefaultConfigHeader()
  );
};

export const newPositionConfig = (position: any, fromYear: any) => {
  const postData = {
    id: position.id ?? null,
    id_position: position.id_position,
    fromDate: fromYear + 1 + "-" + moment().format("MM-DD"),
    personal: position.personal ?? null,
    performance_evaluation: position.performance_evaluation ?? null,
    corporative: position.corporative ?? null,
  };

  return axios.post(
    process.env.REACT_APP_API_BACKEND + "/position/config",
    postData,
    getDefaultConfigHeader()
  );
};

export const newPositionConfigCurrent = (position: any, fromYear: any) => {
  const postData = {
    id: position.id ?? null,
    id_position: position.id_position,
    fromDate: fromYear + "-" + moment().format("MM-DD"),
    personal: position.personal ?? null,
    performance_evaluation: position.performance_evaluation ?? null,
    corporative: position.corporative ?? null,
  };
  return axios.post(
    process.env.REACT_APP_API_BACKEND + "/position/config",
    postData,
    getDefaultConfigHeader()
  );
};
export const addPosition = (
  name: string,
  id_company: number,
  percent: number
) => {
  return axios.post(
    process.env.REACT_APP_API_BACKEND +
      "/position?name=" +
      name +
      "&id_company=" +
      id_company +
      "&percent=" +
      percent,
    {},
    getDefaultConfigHeader()
  );
};

export const updatePosition = (
  name: string,
  id: number,
  id_company: number,
  porcentaje: number
) => {
  const body = {
    name: name,
    id: id,
    id_company: id_company,
    porcentaje: porcentaje,
  };
  return axios.put(
    process.env.REACT_APP_API_BACKEND + "/position",
    body,
    getDefaultConfigHeader()
  );
};
export const deletePosition = (id: number) => {
  return axios.delete(
    process.env.REACT_APP_API_BACKEND + "/position?id=" + id,
    getDefaultConfigHeader()
  );
};
