import axios from "axios";
import { toast } from "react-toastify";
import { getDefaultConfigHeader } from "../../Utils/headerDefaultConfigPetition";

export const getAllCompany = async () => {
  return await axios
    .get(
      process.env.REACT_APP_API_BACKEND + "/company",
      getDefaultConfigHeader()
    )
    .catch((error) => {
      toast.error("Ha habido un problema, intentelo más tarde");
    });
};

export const createCompany = async (company: any) => {
  return await axios
    .post(
      process.env.REACT_APP_API_BACKEND + "/company",
      company,
      getDefaultConfigHeader()
    )
    .catch((error) => {
      console.log(error);
    });
};

export const updateCompany = async (company: any) => {
  return await axios
    .put(
      process.env.REACT_APP_API_BACKEND + "/company",
      company,
      getDefaultConfigHeader()
    )
    .catch((error) => {
      toast.error("Ha habido un problema, intentelo más tarde");
    });
};

export const removeCompany = async (company: any) => {
  return await axios
    .delete(
      process.env.REACT_APP_API_BACKEND + "/company/" + company.id,
      getDefaultConfigHeader()
    )
    .catch((error) => {
      if (error.response.status === 404) {
        toast.error(
          "No se puede borrar una empresa que tiene empleados asignados"
        );
        const errorInfo = new Error("No se puede borrar");
        throw errorInfo;
      } else {
        toast.error("Ha habido un problema, intentelo más tarde");
      }
    });
};
