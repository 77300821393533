import axios from "axios";
import { toast } from "react-toastify";
import { getDefaultConfigHeader } from "../../Utils/headerDefaultConfigPetition";

export const getAllEmployee = () => {
  return axios
    .get(
      process.env.REACT_APP_API_BACKEND + "/employee",
      getDefaultConfigHeader()
    )
    .catch((error) => toast.error("Ha habido un error"));
};

export const getAllEmployeeTable = () => {
  return axios
    .get(
      process.env.REACT_APP_API_BACKEND + "/employee/date/",
      getDefaultConfigHeader()
    )
    .catch((error) => toast.error("Ha habido un error"));
};

export const updateEmployee = (
  id_employee: number,
  id_company: number,
  delegation: any,
  id_tag: any
) => {
  return axios
    .put(
      process.env.REACT_APP_API_BACKEND + "/employee",
      {
        id_employee: id_employee,
        id_company: id_company,
        delegacion_provincial: delegation === 0 ? null : delegation,
        id_tag: id_tag,
      },
      getDefaultConfigHeader()
    )
    .catch((error) => toast.error("Ha habido un error"));
};

export const getOrganigrama = (id_company: number) => {
  return axios
    .get(
      process.env.REACT_APP_API_BACKEND +
        "/employee/organigrama/?id_company=" +
        id_company,
      getDefaultConfigHeader()
    )
    .catch((error) => {
      if (error.response.status === 404) {
        const errorInfo = new Error("No hay datos para este año");
        throw errorInfo;
      } else {
        toast.error("Ha habido un error");
      }
    });
};

export const getAssignmentEmployees = () => {
  return axios
    .get(
      process.env.REACT_APP_API_BACKEND + "/employee/getAsignacion/",
      getDefaultConfigHeader()
    )
    .catch((error) => toast.error("No hay datos para este año"));
};

export const addAssignment = (id_manager: number, id_employee: number) => {
  return axios
    .post(
      process.env.REACT_APP_API_BACKEND +
        "/employee/addAsignacion/" +
        "?id_man=" +
        id_manager +
        "&id_emp=" +
        id_employee,
      {
        id_manager: id_manager,
        id_employee: id_employee,
      },
      getDefaultConfigHeader()
    )
    .catch((error) => toast.error("Ha habido un error"));
};

export const removeAssignment = (id_manager: number) => {
  return axios
    .delete(
      process.env.REACT_APP_API_BACKEND +
        "/employee/delAsig" +
        "?id_man=" +
        id_manager,
      getDefaultConfigHeader()
    )
    .catch((error) => toast.error("Ha habido un error"));
};

export const assignmentPositionEmployees = (
  year: string,
  id_emp: number,
  id_position: number,
  delegacion_provincial: number
) => {
  return axios.put(
    process.env.REACT_APP_API_BACKEND + "/employee",
    {
      id_employee: id_emp,
      id_position: id_position,
      delegacion_provincial: delegacion_provincial,
    },
    getDefaultConfigHeader()
  );
};

export const changeActivation = (activation: boolean, employee: any) => {
  return axios.put(
    process.env.REACT_APP_API_BACKEND +
      "/employee/activation?activate=" +
      activation,
    employee,
    getDefaultConfigHeader()
  );
};

export const getEmployeeByCompany = (
  id_company: number,
  id_employee: number
) => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND +
      "/employee/company?id_company=" +
      id_company +
      "&id_employee=" +
      id_employee,
    getDefaultConfigHeader()
  );
};

export const getEmployeeByID = (
  // id_company: number,
  id_employee: number
) => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/employee/" + id_employee,
    getDefaultConfigHeader()
  );
};
