import axios from "axios";
import { getDefaultConfigHeader } from "../../Utils/headerDefaultConfigPetition";

export const getNotifications = () => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/employee/notifications/",
    getDefaultConfigHeader()
  );
};

export const activeNotificationWeek = (id_employee: number) => {
  return axios.post(
    process.env.REACT_APP_API_BACKEND +
      `/employee/notifications/add?id_new_employee=${id_employee}&id_new_notification=1`,
    {},
    getDefaultConfigHeader()
  );
};
export const desactiveNotificationWeek = (id_employee: number) => {
  return axios.delete(
    process.env.REACT_APP_API_BACKEND +
      `/employee/notifications/delete?id_new_employee=${id_employee}&id_new_notification=1`,
    getDefaultConfigHeader()
  );
};
