import * as React from "react";
import "./BoxStatistics.css";
export default function BoxStatistics(props: any) {
  return (
    <div className="">
      <div className="boxStatistics row">
        <div className="boxStatisticsHeader">
          <h1 className="boxStatisticsTitle">{props.title}</h1>
        </div>
        <div>
          <h2 className="boxStatisticsValue">{props.value}</h2>
        </div>
      </div>{" "}
    </div>
  );
}
