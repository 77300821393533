import axios from "axios";
import { toast } from "react-toastify";
import { getDefaultConfigHeader } from "../../Utils/headerDefaultConfigPetition";

export const getAllCostsCenter = async () => {
  return await axios
    .get(process.env.REACT_APP_API_BACKEND + "/tag", getDefaultConfigHeader())
    .catch((error) => {
      toast.error("Ha habido un problema, intentelo más tarde");
    });
};

export const createCostCenter = async (costCenter: any) => {
  return await axios
    .post(
      process.env.REACT_APP_API_BACKEND + "/tag",
      costCenter,
      getDefaultConfigHeader()
    )
    .catch((error) => {
      toast.error(
        "Ha habido un problema al crear el centro de coste, inténtelo más tarde"
      );
      console.log(error);
    });
};

export const updateCostCenter = async (costCenter: any) => {
  return await axios
    .put(
      process.env.REACT_APP_API_BACKEND + "/tag",
      costCenter,
      getDefaultConfigHeader()
    )
    .catch((error) => {
      toast.error(
        "Ha habido un problema al actulizar el centro de coste, inténtelo más tarde"
      );
      console.log(error);
    });
};

export const removeCostCenter = async (costCenter: any) => {
  return await axios
    .delete(
      process.env.REACT_APP_API_BACKEND + "/tag/" + costCenter.id,
      getDefaultConfigHeader()
    )
    .catch((error) => {
      if (error.response.status === 404) {
        toast.error(
          "El centro de coste tiene algún empleado asignado y no puede ser eliminado"
        );
      } else {
        toast.error("Ha habido un problema, inténtelo más tarde");
      }
    });
};
