import axios from "axios";
import { getDefaultConfigHeader } from "../../Utils/headerDefaultConfigPetition";

export const getCurrentCorpotativeObjective = (year: number) => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/objetives/corp/yearAct/" + year,
    getDefaultConfigHeader()
  );
};

export const getNextCorpotativeObjective = (year: number) => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/objetives/corp/yearSig/" + year,
    getDefaultConfigHeader()
  );
};

export const addNewCorpotativeObjective = (
  name: string,
  id_company: number
) => {
  return axios.post(
    process.env.REACT_APP_API_BACKEND +
      "/objectives/corporative?name=" +
      name +
      "&id_company=" +
      id_company,
    {},
    getDefaultConfigHeader()
  );
};

export const addNewCorpotativeObjectiveCurrentYear = (
  name: string,
  id_company: number
) => {
  return axios.post(
    process.env.REACT_APP_API_BACKEND +
      "/objectives/corporative/current?name=" +
      name +
      "&id_company=" +
      id_company,
    {},
    getDefaultConfigHeader()
  );
};

export const updateNewCorpotativeObjective = (config: any) => {
  return axios.put(
    process.env.REACT_APP_API_BACKEND + "/objectives/corporative",
    config,
    getDefaultConfigHeader()
  );
};
