import axios from "axios";
import { getDefaultConfigHeader } from "../../Utils/headerDefaultConfigPetition";

export const getSteeringCommittee = async () => {
  return await axios.get(
    process.env.REACT_APP_API_BACKEND + "/ceos/",
    getDefaultConfigHeader()
  );
};

export const validateSteeringCommittee = async (
  id_template: number,
  id_employee: number
) => {
  return axios.post(
    process.env.REACT_APP_API_BACKEND +
      "/validations/ceos/accept?id_template=" +
      id_template +
      "&id_validator=" +
      id_employee,
    {},
    getDefaultConfigHeader()
  );
};
