export const getCurrentPeriod = (year: number): string => {
  let period: string = "";
  period = year + "-" + (year + 1);
  return period;
};

export const getNextPeriod = (year: number): string => {
  let period: string = "";
  period = year + 1 + "-" + (year + 2);
  return period;
};
