import React, { useEffect, useMemo, useState } from "react";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import moment from "moment";
import "./YearSelector.css";
function YearSelector(props: any) {
  const [fromDate, setFromDate] = useState<string>(
    props.date ?? moment("2022", "YYYY").toISOString()
  );

  useEffect(() => {
    if (props.date) {
      props.setDate(fromDate);
    }
  }, [fromDate, props]);

  const handleNextYear = () => {
    setFromDate(moment(fromDate).add(1, "year").toISOString());
  };

  const handlePreviousYear = () => {
    setFromDate(moment(fromDate).subtract(1, "year").toISOString());
  };

  const isDisable = useMemo(() => {
    if (moment(fromDate).isBefore(moment("2022"))) {
      return true;
    }
    return false;
  }, [fromDate]);

  return (
    <div className="selectDate row">
      <div
        style={{ position: "relative" }}
        className="col-2 d-flex align-items-center"
      >
        <div
          style={{
            position: "absolute",
            background: "#80808091",
            width: "45px",
            height: "70px",
            display: isDisable ? "block" : "none",
          }}
        ></div>
        <IconButton onClick={handlePreviousYear} disabled={isDisable}>
          <ArrowBackIcon style={{ fill: "white !important" }}></ArrowBackIcon>
        </IconButton>
      </div>
      <div className="col-8">
        <h1 className="yearSelect">
          {moment(fromDate).format("YYYY") +
            "-" +
            moment(fromDate).add(1, "year").format("YYYY")}
        </h1>
      </div>
      <div className="col-2 d-flex align-items-center justify-content-end">
        <IconButton onClick={handleNextYear}>
          <ArrowForwardIcon
            style={{ fill: "white !important" }}
          ></ArrowForwardIcon>
        </IconButton>
      </div>
    </div>
  );
}

export default YearSelector;
