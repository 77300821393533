import { GridColDef, GridRenderEditCellParams } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  createPercentageNextYear,
  getAllPercentageByYear,
  removePercentage,
  updatePercentage,
} from "../../../../Service/PercentageService/PercentageService";
import AedasLoading from "../../../Shared/AedasLoading/AedasLoading";
import DataGridEditabled from "../../../Shared/DataGridEditabled/DataGridEditabled";
import "./Percentage.css";

function Percentage(props: any) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const year = useSelector((state: any) => state.yearReducer.year);

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 9, hide: true },
    {
      field: "number",
      headerName: "Procentaje",
      minWidth: 500,
      flex: 1,
      editable: true,
      sortable: false,
      renderCell: (params: GridRenderEditCellParams) => {
        return params.row.number ? params.row.number + "%" : "";
      },
      type: "number",
    },
  ];
  useEffect(() => {
    if (isLoading) {
      let promises: any = [];
      promises.push(
        getAllPercentageByYear(year + 1)
          .then((response: any) => {
            setData(response.data);
          })
          .catch((error) => {
            if (error.request.status === 404) {
            } else {
              toast.error("Ha habido un problema");
            }
          })
      );
      Promise.all(promises).finally(() => setIsLoading(false));
    }
  }, [isLoading, year]);

  if (isLoading) {
    return <AedasLoading></AedasLoading>;
  }
  const handleSave = (props: any) => {
    let promisesArray: any = [];
    if (props.news.length > 0) {
      props.news.forEach((percentage: any) => {
        if (percentage.number) {
          promisesArray.push(createPercentageNextYear(percentage.number));
        }
      });
    }
    props.edited.forEach((percentage: any) => {
      if (percentage.number) {
        promisesArray.push(
          updatePercentage({
            id: percentage.id,
            fromYear: percentage.fromYear,
            number: percentage.number,
          })
        );
      }
    });

    props.removed.forEach((percentage: any) => {
      promisesArray.push(removePercentage(percentage.id));
    });
    Promise.all(promisesArray).then(() => setIsLoading(true));
  };
  return (
    <div className="currentObjetives_Table">
      <DataGridEditabled
        onlyEditFunct={true}
        rows={data}
        columns={columns}
        handleSave={handleSave}
      />
    </div>
  );
}

export default Percentage;
