import { Tooltip } from "@mui/material";
import {
  GridColDef,
  GridRenderEditCellParams,
  useGridApiContext,
} from "@mui/x-data-grid";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getObjectivesByYear } from "../../../../../Service/ObjectiveService/ObjectiveService";
import { getAllPercentageByYear } from "../../../../../Service/PercentageService/PercentageService";
import { sortObjPersonal } from "../../../../../Utils/integerUtils";
import AedasLoading from "../../../../Shared/AedasLoading/AedasLoading";
import DataGridEditabled from "../../../../Shared/DataGridEditabled/DataGridEditabled";

export default function EditPersonalObjectives(props: any) {
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [isLoading, setisLoading] = useState(true);
  let dataCopy: any = [];
  let percentageCopy = 0;

  const year = useSelector((state: any) => state.yearReducer.year);
  const [columns, setColumns] = useState<GridColDef[]>([
    { field: "id", headerName: "ID", width: 9, hide: true },

    { field: "header", headerName: "Header", width: 9, hide: true },
    {
      field: "objective",
      headerName: "Objetivo",
      width: 350,
      editable: true,
      sortable: false,
      renderCell: (params) => {
        return (
          <Tooltip title={params.value}>
            <div
              style={{
                width: "100%",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {params.value}
            </div>
          </Tooltip>
        );
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return <CustomObjectiveInput {...params} />;
      },
    },
    {
      field: "valoration",
      headerName: "Valoración % s/retirb. fija",
      width: 200,
      editable: true,
      sortable: false,

      renderCell: (params: GridRenderEditCellParams) => {
        return (
          <div>
            {" "}
            {(params.row.valoration ? params.row.valoration + "%" : "").replace(
              ".",
              ","
            )}
          </div>
        );
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <CustomPercentageInput
            {...params}
            setError={setError}
            data={dataCopy}
            percentage={percentageCopy}
          />
        );
      },
    },
  ]);

  useEffect(() => {
    if (!props.buttonLoading) {
      setisLoading(true);
    }
  }, [props.buttonLoading]);

  useEffect(() => {
    if (isLoading) {
      getObjectivesByYear(props.id, year)
        .then((response) => {
          getAllPercentageByYear(year).then((responsePercentage: any) => {
            processData(response.data, responsePercentage.data);
          });
        })
        .catch((error) => console.log(error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, props.id, year]);

  const processData = (objectives: any, percentage: any) => {
    let auxColums: any = [];
    percentage.forEach((p: any) => {
      auxColums.push({
        renderCell: (params: any) => {
          return (
            <Tooltip title={params.value}>
              <div
                style={{
                  width: "100%",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  textAlign: "center",
                }}
              >
                {params.value}
              </div>
            </Tooltip>
          );
        },
        field: p.number.toString(),
        headerName: p.number + "%",
        minWidth: 110,
        flex: 1,
        editable: true,
        sortable: false,
      });
    });

    setColumns(columns.concat(auxColums));
    let auxArray: any = [];
    let arrayPersonal: any = [];
    let percentagePersonal = 0;

    objectives.forEach((obj: any) => {
      if (obj.obj_type === "PERSONAL") {
        let personal: any = [];

        personal = obj.objectives.map((objectCorporative: any, i: number) => {
          let auxTuple: any = {};
          objectCorporative.percentages.forEach((per: any) => {
            let value: string = per[0].toString();
            auxTuple[value] = per[1];
          });
          return { ...objectCorporative, ...auxTuple };
        });
        arrayPersonal = personal;
        percentagePersonal = obj.percentage;
      }
    });

    auxArray = auxArray.concat(arrayPersonal);
    setPercentage(percentagePersonal);
    setData(auxArray.sort(sortObjPersonal));
    dataCopy = auxArray;
    percentageCopy = percentagePersonal;
    setisLoading(false);
  };

  if (isLoading) {
    return <AedasLoading></AedasLoading>;
  }
  return (
    <>
      <div className="d-flex justify-content-center  w-100">
        <h1 className={!error ? "asignacionTitle" : "asignacionTitleError"}>
          Maxima valoración Asignable {percentage}%
        </h1>
      </div>
      <div style={{ height: "400px" }}>
        <DataGridEditabled
          disableColumnFilter
          disableColumnSelector
          disableColumnMenu
          onlyEditFunct={true}
          autoSave={true}
          columns={columns}
          rows={data}
          handleSave={props.handleSave}
          handleAutoSave={props.handleAutoSave}
        ></DataGridEditabled>
      </div>
    </>
  );
}

function CustomPercentageInput(props: any) {
  const { id, field } = props;

  const apiRef = useGridApiContext();
  const ref = useRef<any>();
  const [error, setError] = useState(false);
  function handleChange(event: any) {
    let valoration = 0;
    let count = 0;
    if (field === "valoration") {
      valoration = parseInt(event.target.value);
    }
    props.data.forEach((r: any, i: number) => {
      if (r.id !== id) {
        count += parseInt(r.valoration ?? 0);
      }
    });

    if (valoration + count > props.percentage) {
      setError(true);
      props.setError(true);
    } else {
      setError(false);
      props.setError(false);
      apiRef.current.setEditCellValue({
        id,
        field,
        value: event.target.value,
      });
    }
  }
  useEffect(() => {
    if (ref) {
      ref.current.focus();
    }
  }, [ref]);
  return (
    <input
      ref={ref}
      onChange={handleChange}
      className={!error ? "inputPercentage" : "inputPercentageError"}
      id="description"
      name="description"
      type="number"
      min="0"
      max="100"
    ></input>
  );
}

function CustomObjectiveInput(props: any) {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();
  const ref = useRef<any>();

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value; // The new value entered by the user
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };
  useEffect(() => {
    if (ref) {
      ref.current.focus();
    }
  }, [ref]);
  return (
    <input
      ref={ref}
      value={value}
      onChange={handleValueChange}
      className={"inputPercentage"}
      type="text"
    ></input>
  );
}
