import DownloadIcon from "@mui/icons-material/Download";
import { IconButton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { PDFDownloadLink } from "@react-pdf/renderer";
import moment from "moment";
import * as React from "react";
import { useSelector } from "react-redux";
import PDFObjective from "../../../Shared/PDFObjective/PDFObjective";
import ReactLoading from "react-loading";

import "./MyObjetivesTable.css";

function MyObjetivesTable(props: any) {
  const user = useSelector((state: any) => state.userReducer.user);
  const year = useSelector((state: any) => state.yearReducer.year);

  console.log(props.columns);
  return (
    <div className="flex-column col-12">
      <h3 className="asignacionTitle col-2">
        {props.rol} {props.percentage}
      </h3>
      <div
        style={{
          width: "95%",
          display: "flex",
          justifyContent: "end",
          paddingLeft: "10px",
        }}
      >
        <PDFDownloadLink
          document={
            <PDFObjective
              percentage={props.percentage}
              year={year}
              data={props.data}
              columns={props.columns}
              dataAll={props.dataAll}
            ></PDFObjective>
          }
          fileName={
            "objetivos_" +
            year +
            "_" +
            (year + 1) +
            "_" +
            user.employee.display_name +
            ".pdf"
          }
        >
          {({ blob, url, loading, error }) =>
            loading ? (
              <ReactLoading type="bubbles" color={"#092E3B"}></ReactLoading>
            ) : (
              <div style={{ display: "flex", alignItems: "center" }}>
                <div>
                  <div
                    style={{
                      margin: "0px",
                      color: "black",
                      textDecoration: "none",
                    }}
                  >
                    Descargar Objetivos
                  </div>
                </div>
                <IconButton>
                  <DownloadIcon />
                </IconButton>
              </div>
            )
          }
        </PDFDownloadLink>
      </div>
      <div className="myObjetives_Table">
        <DataGrid
          rows={props.data}
          columns={props.columns}
          disableSelectionOnClick
          hideFooter
          disableColumnFilter
          disableColumnSelector
          disableColumnMenu
          getRowClassName={(params) => {
            if (params.row.header) {
              return `greyBackground`;
            }
            return "";
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ width: "90%" }}>
          <span
            style={{
              fontWeight: "bold",
              marginRight: 3,
            }}
          >
            Observaciones:
          </span>
          Estas cifras no contemplan ningún proyecto de coinversión, siendo
          inferiores a la aprobadas en Consejo de Administración para el Plan de
          Negocio.
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "20px",
          justifyContent: "center",
          padding: "20px",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <img
            alt="Firma manager"
            style={{ maxWidth: "300px", maxHeight: "130px" }}
            src={props.dataAll.firma_manager}
          ></img>
          <h3 style={{ fontSize: "12px", padding: "10px" }}>
            Firmado por {props.dataAll.firmado_orden ? "orden por " : ""}{" "}
            {props.dataAll.manager_name} el día{" "}
            {moment(props.dataAll.updated_at).format("DD-MM-YYYY HH:ss")}
          </h3>
        </div>
        <div
          style={{
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <img
            alt="Firma empleado"
            style={{ maxWidth: "300px", maxHeight: "130px" }}
            src={props.dataAll.firma_employee}
          ></img>
          <h3 style={{ fontSize: "12px", padding: "10px" }}>
            {" "}
            Firmado por {props.dataAll.employee_name} el día{" "}
            {moment(props.dataAll.updated_at).format("DD-MM-YYYY HH:ss")}
          </h3>
        </div>
      </div>
    </div>
  );
}

export default MyObjetivesTable;
