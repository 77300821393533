import * as React from "react";
import { useSelector } from "react-redux";
import { Tab, TabList, Tabs, TabPanel } from "react-tabs";
import { getNextPeriod } from "../../../Utils/aedasDateUtil";
import "./NextYear.css";
import Objetives from "./Objetives/Objetives";
import Percentage from "./Percentage/Percentage";
import Publish from "./Publish/Publish";
import Roles from "./Roles/Roles";

function NextYear() {
  const year = useSelector((state: any) => state.yearReducer.year);

  return (
    <div className="px-4 h-100">
      <h1 className="titleNextYear">Objetivos de {getNextPeriod(year)}</h1>
      <Tabs>
        <TabList>
          <Tab>Objetivos</Tab>
          <Tab>Roles</Tab>
          <Tab>Porcentajes</Tab>
          <Tab>Publicar</Tab>
        </TabList>

        <TabPanel>
          <Objetives></Objetives>
        </TabPanel>
        <TabPanel>
          <Roles></Roles>
        </TabPanel>
        <TabPanel>
          <Percentage></Percentage>
        </TabPanel>
        <TabPanel>
          <Publish></Publish>
        </TabPanel>
      </Tabs>
    </div>
  );
}

export default NextYear;
