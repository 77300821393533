import moment from "moment";
import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import {
  generateExcels,
  getExcelAllExcelZip,
  getExcellStatusByYear,
} from "../../../Service/ExcelService/ExcelService";
import AedasLoading from "../../Shared/AedasLoading/AedasLoading";
import ReactLoading from "react-loading";
import { toast } from "react-toastify";
//@ts-ignore
import { saveAs } from "file-saver";

function ExcelAllDownload(props: any) {
  const { year } = props;
  const [status, setStatus] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [generating, setGenerating] = useState(false);

  useEffect(() => {
    if (loading) {
      getExcellStatusByYear(year).then((response) => {
        setStatus(response.data);
        setLoading(false);
      });
    }
  }, [loading]);

  const handleDownload = () => {
    setGenerating(true);
    getExcelAllExcelZip(year).then((response: any) => {
      let blob = new Blob([response.data], {
        type: "application/zip",
      });
      saveAs(blob, "objetivos_" + year + "_" + (year + 1) + "_Todos" + ".zip");
      setGenerating(false);
    });
  };
  const handleGenerate = () => {
    setGenerating(true);
    generateExcels(year)
      .then((response) => {
        toast.success("Este proceso puede durar unos minutos");
        setGenerating(false);
        setLoading(true);
      })
      .catch((err) => {
        toast.error("Ha habido un error");
        setGenerating(false);
      });
  };
  if (loading || generating) {
    <div
      style={{
        display: "flex",
        gap: "10px",
        justifyContent: "end",
        alignItems: "center",
        paddingBottom: "1rem",
      }}
    >
      <ReactLoading type="bubbles" color={"#092E3B"}></ReactLoading>
    </div>;
  }
  switch (status?.status) {
    case "Finalizado":
      return (
        <div
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "end",
            alignItems: "center",
            paddingBottom: "1rem",
          }}
        >
          <span>
            Ultima actualización:{" "}
            {moment(status.updated_at).format("DD-MM-YYYY HH:mm")}
          </span>
          <Button
            color="primary"
            className="showObjetives_Button"
            onClick={handleDownload}
          >
            Descargar
          </Button>
          <Button
            color="primary"
            className="showObjetives_Button"
            onClick={handleGenerate}
          >
            Volver a Generar
          </Button>
        </div>
      );

    case "Generando":
      return (
        <div
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "end",
            alignItems: "center",
            paddingBottom: "1rem",
          }}
        >
          <span>Genenrando. Este proceso puede tardar minutos</span>
          <ReactLoading type="bubbles" color={"#092E3B"}></ReactLoading>
        </div>
      );
    case "Generando":
      return (
        <div
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "end",
            alignItems: "center",
            paddingBottom: "1rem",
          }}
        >
          <span>Genenrando. Este proceso puede tardar minutos</span>
          <ReactLoading type="bubbles" color={"#092E3B"}></ReactLoading>
        </div>
      );
    case "Preparado":
      return (
        <div
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "end",
            alignItems: "center",
            paddingBottom: "1rem",
          }}
        >
          <Button
            color="primary"
            className="showObjetives_Button"
            onClick={handleGenerate}
          >
            Generar todos los Excels
          </Button>
        </div>
      );
    default:
      return <>Error</>;
      break;
  }
}

export default ExcelAllDownload;
