import * as React from "react";
import { WhiteSpaceWithLabelProps } from "../../../Models/Shared/WhiteSpaceWithLabel/WhiteSpaceWithLabel";
import "./whiteSpaceWithTitle.css";

function WhiteSpaceWithTitle(props: WhiteSpaceWithLabelProps) {
  return (
    <div className="whiteSpace">
      <h1 className="titleSpace">EQUIPO</h1>
    </div>
  );
}

export default WhiteSpaceWithTitle;
