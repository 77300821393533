import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { getRejectDescripton } from "../../../../../Service/TemplateService/TemplateService";
import AedasLoading from "../../../../Shared/AedasLoading/AedasLoading";
import CustomModal from "../../../../Shared/ModalCustom/CustomModal";
import EditPersonalObjectives from "./EditPersonalObjectives";
import ShowObjetives from "./ShowObjectives";

function ManagerObjectivesModal(props: any) {
  const [openModal, setOpenModal] = useState(false);
  const { handleSave, setrowsAux, rowsAux, handleAutoSave } = props;
  return (
    <>
      <CustomModal
        size="md"
        show={openModal}
        onChange={() => {
          setOpenModal(!openModal);
        }}
        title="Confirmar envio a validar"
        body={
          <div>
            <h1
              style={{
                fontSize: "20px",
                textAlign: "center",
                background: "white",
                padding: "10px",
              }}
            >
              Confirmo enviar a validar la plantilla por mi manager
            </h1>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                onClick={() => setOpenModal(false)}
                style={{
                  backgroundColor: "var(--aedas-red)",
                  height: "30px",
                  marginLeft: "1rem",
                }}
                variant="contained"
                className="sign_button"
              >
                Cancelar
              </Button>
              <Button
                onClick={() => {
                  setOpenModal(false);
                  props.handleSaveAndSendTovalidate(rowsAux);
                }}
                style={{
                  backgroundColor: "var(--aedas-green)",
                  height: "30px",
                  marginLeft: "1rem",
                }}
                variant="contained"
                className="sign_button"
              >
                Enviar a validar
              </Button>
            </div>
          </div>
        }
      ></CustomModal>
      <h3 className="asignacionTitle">
        Objetivos de: {props.modalData?.display_name}
        {props.modalData?.id_status === 1 ||
        props.modalData?.id_status === 5 ? (
          <LoadingButton
            loading={props.IsLoadingButtonSendValidate}
            onClick={() => setOpenModal(true)}
            style={{
              backgroundColor: "var(--aedas-green)",
              height: "30px",
              marginLeft: "1rem",
            }}
            variant="contained"
            className="sign_button"
          >
            Enviar a validar
          </LoadingButton>
        ) : (
          <></>
        )}
      </h3>

      <Tabs
        onSelect={(index: number, lastIndex: number, event: Event) => {
          if (lastIndex === 1 && rowsAux) {
            handleSave(rowsAux);
          } else if (lastIndex !== 1) {
            setrowsAux(null);
          }
        }}
      >
        <TabList>
          <Tab>Objetivos</Tab>

          {props.modalData?.id_status === 1 ? (
            <>
              <Tab>Editar</Tab>
            </>
          ) : (
            <></>
          )}
          {props.modalData?.id_status === 5 ? (
            <>
              <Tab>Editar</Tab>
              <Tab>Causa del rechazo</Tab>
            </>
          ) : (
            <></>
          )}
        </TabList>
        <TabPanel>
          <ShowObjetives modalData={props.modalData}></ShowObjetives>
        </TabPanel>
        <TabPanel>
          <EditPersonalObjectives
            buttonLoading={props.IsLoadingButtonSendValidate}
            handleSave={handleSave}
            handleAutoSave={handleAutoSave}
            id={props.modalData?.id_employee}
          />
        </TabPanel>
        <TabPanel>
          <RejectDescription modalData={props.modalData}></RejectDescription>
        </TabPanel>
      </Tabs>
    </>
  );
}

export default ManagerObjectivesModal;

function RejectDescription(props: any) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<any>(true);

  useEffect(() => {
    getRejectDescripton(props.modalData.id_template).then((response) => {
      setData(response.data);
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <AedasLoading></AedasLoading>;
  }

  return (
    <>
      <div className="d-flex justify-content-center w-100">
        <div className="boxPublish">
          <h1 style={{ fontSize: "25px" }}>
            {data.display_name} ha rechazado los objetivos por:
          </h1>
          <p>{data.descripcion}</p>
        </div>
      </div>
    </>
  );
}
