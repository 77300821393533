import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import AedasLoading from "../AedasLoading/AedasLoading";
import "./TeamPersonBox.css";

function TeamPersonBox(props: any) {
  const { title, subtitle, state, img } = props;
  const [StateName, setState] = useState<any>(null);

  const [isLoading, setIsLoading] = useState(true);

  const states = [
    { id: 1, name: "Pendiente", color: "#BBBCBC" },
    { id: 2, name: "Validando", color: "#041C2C" },
    { id: 3, name: "Pendiente de R.", color: "#88DBDF" },
    { id: 4, name: "Firmado", color: "#00B176" },
    { id: 5, name: "Rechazado", color: "#FF7E31" },
    { id: 6, name: "Rechazado", color: "#FF7E31" },
  ];

  useEffect(() => {
    let s: any = states.find((s) => s.id === state);
    if (s) {
      setState(s);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  if (isLoading) {
    return <AedasLoading></AedasLoading>;
  }
  return (
    <>
      <div className={!props.admin ? "team_container_self" : "team_container"}>
        <div className="img_container">
          <img
            alt="Imagen de persona"
            className="imgPerson"
            style={{ border: `5px solid ${StateName.color}` }}
            src={process.env.REACT_APP_API_BACKEND + "/" + img}
          ></img>
        </div>
        <div className={!props.admin ? "whiteBox_self" : "whiteBox"}>
          <h1 className="titleNameTeam">{title}</h1>
          <h2 className="subtitleNameTeam">{subtitle}</h2>
          <div className="moreActionsTeam_container row">
            <div className="col-12 d-flex justify-content-center">
              <span
                style={{ backgroundColor: StateName.color }}
                className="chipTeamStatus"
              >
                {" "}
                {StateName ? StateName.name : ""}
              </span>
            </div>
            {props.admin ? (
              <div
                className="col-12 d-flex justify-content-center"
                style={{ display: props.admin ? "block" : "none" }}
              >
                <Button
                  fullWidth
                  color="primary"
                  className="teamManage_button mx-3"
                  onClick={() => props.handelOpenModal(props.id)}
                >
                  Administrar Objetivos
                </Button>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default TeamPersonBox;
