import Checkbox from "@mui/material/Checkbox";
import {
  GridColDef,
  GridRenderEditCellParams,
  useGridApiContext,
} from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getAllEmployee } from "../../../Service/EmployeeService/EmployeeConfig";
import {
  activeNotificationWeek,
  desactiveNotificationWeek,
  getNotifications,
} from "../../../Service/NotificationService/NotificationService";
import AedasLoading from "../../Shared/AedasLoading/AedasLoading";
import DataGridEditabled from "../../Shared/DataGridEditabled/DataGridEditabled";

function EmployeeNotifications(props: any) {
  const [Data, setData] = useState([]);
  //const [Employees, setEmployees] = useState([]);
  //const [Notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
  }, [props.date]);

  useEffect(() => {
    if (isLoading) {
      let promises = [];
      promises.push(
        getAllEmployee().then((response: any) => {
          //setEmployees(response.data);
          getNotifications().then((responseNotify: any) => {
            //setNotifications(responseNotify.data);
            processData(response.data, responseNotify.data);
          });
        })
      );

      Promise.all(promises).catch(() => toast.error("Ha habido un problema"));
    }
  }, [isLoading]);

  const processData = (employees: any, notifications: any) => {
    let aux: any = [];
    employees.forEach((emp: any) => {
      aux.push({
        name: emp.display_name,
        active:
          notifications.find((e: any) => e.id_employee === emp.id).notifications
            .length > 0
            ? true
            : false,
        id: emp.id,
      });
    });
    setData(aux);
    setIsLoading(false);
  };
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 9,
      hide: true,
      editable: false,
    },

    {
      field: "name",
      headerName: "Empleado",
      type: "text",
      width: 200,
      editable: false,
      flex: 1,
    },

    {
      field: "active",
      headerName: "Activo",
      type: "text",
      renderCell: (params: GridRenderEditCellParams) => {
        return <>{params.value === true ? "Activado" : "Desactivado"}</>;
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return <CustomEditComponent {...params}></CustomEditComponent>;
      },
      width: 200,
      editable: true,
      flex: 1,
    },
  ];

  const handleSave = (data: any) => {
    let promises: any = [];
    data.edited.forEach((notification: any) => {
      if (notification.active) {
        promises.push(activeNotificationWeek(notification.id));
      } else {
        promises.push(desactiveNotificationWeek(notification.id));
      }
    });
    Promise.all(promises)
      .then(() => toast.success("Guardado"))
      .catch(() => toast.error("Ha habido un problema"));
  };
  return (
    <>
      {isLoading ? (
        <AedasLoading></AedasLoading>
      ) : (
        <div className="px-4">
          <h1 className="titleTeams">Notificaciones</h1>
          <div style={{ height: "80vh" }}>
            <DataGridEditabled
              onlyEditFunct={true}
              onlyEdit={true}
              handleSave={handleSave}
              rows={Data}
              columns={columns}
              title=""
            ></DataGridEditabled>
          </div>
        </div>
      )}
    </>
  );
}

export default EmployeeNotifications;

function CustomEditComponent(props: any) {
  const { id, value, field } = props;
  const [active, setActive] = useState(value);
  const apiRef = useGridApiContext();

  const handleChange = (event: any, newValue: any | null) => {
    setActive(!active);
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return <Checkbox checked={active} onChange={handleChange} />;
}
