import * as React from "react";
import { useSelector } from "react-redux";
import "./HomeBox.css";
function HomeBox(props: any) {
  const user = useSelector((state: any) => state.userReducer.user);
  //const position = useSelector((state: any) => state.userReducer.position);

  return (
    <div className="w-100 position-relative">
      <div
        style={{ top: "-100px" }}
        className="d-flex justify-content-center w-100 h-100 position-absolute"
      >
        <img
          alt="Imagen persona"
          className="imgPersonHome"
          src={process.env.REACT_APP_API_BACKEND + "/" + user.employee.url_img}
        ></img>
      </div>
      <div className="d-flex justify-content-center w-100 h-100">
        <div className="divBox">
          <h1 className="titleName">{user.employee.display_name}</h1>
          <h2 className="subtitleRol">{user.employee.azure_rol}</h2>
          {props.children}
        </div>
      </div>
    </div>
  );
}

export default HomeBox;
