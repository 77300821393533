import { Configuration, PopupRequest } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: "d51b0ba4-5a9d-4720-9001-7f3f22130347",
    authority:
      "https://login.microsoftonline.com/1eac135e-ccdf-4d83-8b4f-efc9038309f0",
    redirectUri: process.env.REACT_APP_BASE_REDIRECT,
    postLogoutRedirectUri: process.env.REACT_APP_BASE_REDIRECT,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true,
    secureCookies: true,
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
  scopes: ["https://graph.microsoft.com/.default"],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
