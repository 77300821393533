import React from "react";
import PropTypes from "prop-types";
import "./customNode.css";


const propTypes = {
  nodeData: PropTypes.object.isRequired,
};
const CustomNode = ({ nodeData }) => {
  return (
    <div className="row node">
      <div className="buttonContainer"></div>

      <div>
        <img
          className="imgTree"
          src={process.env.REACT_APP_API_BACKEND + "/" + nodeData.img}
          alt=""
        ></img>
      </div>
      <div className="img">
        <h1 style={{ fontSize: "20px" }}>{nodeData.name}</h1>
        <h1 style={{ fontSize: "15px", color: "grey" }}>{nodeData.subtitle}</h1>
      </div>
      <div>
        <a style={{ fontSize: "12px", color: "grey" }}>
          {nodeData.children && nodeData.children.length > 0
            ? nodeData.children.length +
              " Empleado" +
              (nodeData.children.length >= 2 ? "s" : "")
            : ""}
        </a>
      </div>
    </div>
  );
};

CustomNode.propTypes = propTypes;

export default CustomNode;
