import { LoadingButton } from "@mui/lab";
import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  rejectSings,
  rejectValidation,
  rejectValidationRRHH,
} from "../../../Service/TemplateService/TemplateService";
import AedasLoading from "../AedasLoading/AedasLoading";
import "./RejectModalContent.css";

function RejectModalContent(props: any) {
  const { selectedRow, setOpen } = props;
  const [isLoading, setisLoading] = useState(true);
  const [description, setDescription] = useState<any>("");
  const userEmployee = useSelector((state: any) => state.userReducer.user);
  const [isLoadingButton, setisLoadingButton] = useState(false);
  const team = useSelector((state: any) => state.teamReducer.team);
  const handleReject = () => {
    setisLoadingButton(true);

    if (props.validation) {
      rejectValidation(
        userEmployee.employee.id,
        selectedRow.id_template,
        description
      )
        .then(() => {
          toast.success("Rechazado");
          setisLoadingButton(false);
          setOpen(false);
        })
        .catch(() => toast.error("Ha habido un error"));
    } else if (props.rrhh) {
      rejectValidationRRHH(
        userEmployee.employee.id,
        selectedRow.id_template,
        description
      )
        .then(() => {
          setisLoadingButton(false);
          toast.success("Rechazado");
          setOpen(false);
        })
        .catch(() => toast.error("Ha habido un error"));
    } else {
      rejectSings(selectedRow.id_template, description)
        .then(() => {
          setisLoadingButton(false);
          toast.success("Rechazado");
          setOpen(false);
        })
        .catch(() => {
          setisLoadingButton(false);
          toast.error("Ha habido un error");
        });
    }
  };
  useEffect(() => {
    setisLoading(false);
  }, [selectedRow, team]);

  if (isLoading) {
    return <AedasLoading></AedasLoading>;
  }
  return (
    <div className="d-flex justify-content-center">
      <div className="boxReject">
        <img
          alt="Imagen persona"
          className="imgPersonRejectModal col-3"
          src={
            process.env.REACT_APP_API_BACKEND + "/" + selectedRow?.url_img ?? ""
          }
        ></img>
        <div className="col-9 row p-4">
          <div className="col-12">
            <h1 className="titleRejectModal">{selectedRow?.display_name}</h1>
          </div>

          <div className="col-12">
            <span className="moreInfoReject">
              Rechazo de la validación de los objetivos.
            </span>
          </div>
          <div className="col-12 pt-2">
            <TextField
              fullWidth
              id="description"
              name="description"
              label="Causas del rechazo"
              multiline
              onChange={(e) => setDescription(e.target.value)}
              required
              rows={4}
            ></TextField>
          </div>
          <div className="col-12 d-flex justify-content-center">
            <LoadingButton
              loading={isLoadingButton}
              onClick={handleReject}
              color="primary"
              className="reject_Button"
            >
              Rechazar
            </LoadingButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RejectModalContent;
