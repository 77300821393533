import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import IconButton from "@mui/material/IconButton";
import React, { useEffect, useState } from "react";
import { getAllCompany } from "../../../Service/CompanyService/CompanyService";
import "./CompanySelector.css";

function CompanySelector(props: any) {
  const [data, setData] = useState([]);
  const [index, setIndex] = useState(0);
  const { setCompany, company } = props;
  useEffect(() => {
    getAllCompany().then((response: any) => setData(response.data));
  }, []);

  const handlePrevius = () => {
    if (index === 0) {
      setIndex(data.length - 1);
    } else {
      setIndex(index - 1);
    }
  };

  useEffect(() => {
    if (data && data.length > 0) {
      setCompany(data[index]);
    }
  }, [index, data, setCompany]);

  const handleNext = () => {
    if (index === data.length - 1) {
      setIndex(0);
    } else {
      setIndex(index + 1);
    }
  };

  return (
    <div className="selectDate row">
      <div className="col-2 d-flex align-items-center">
        <IconButton onClick={handlePrevius}>
          <ArrowBackIcon style={{ fill: "white !important" }}></ArrowBackIcon>
        </IconButton>
      </div>
      <div className="col-8">
        <h1 className="companySelect">{company.name}</h1>
      </div>
      <div className="col-2 d-flex align-items-center justify-content-end">
        <IconButton onClick={handleNext}>
          <ArrowForwardIcon
            style={{ fill: "white !important" }}
          ></ArrowForwardIcon>
        </IconButton>
      </div>
    </div>
  );
}

export default CompanySelector;
