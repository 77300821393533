import { Autocomplete, TextField } from "@mui/material";
import {
  GridColDef,
  GridRenderEditCellParams,
  useGridApiContext,
} from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import {
  deleteDelegation,
  getAllDelegations,
  updateOrCreateDelegations,
} from "../../../../Service/DelegationService/DelegationService";
import { getAllEmployee } from "../../../../Service/EmployeeService/EmployeeConfig";
import AedasLoading from "../../../Shared/AedasLoading/AedasLoading";
import DataGridEditabled from "../../../Shared/DataGridEditabled/DataGridEditabled";

function Delegation(props: any) {
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const [delegations, setDelegations] = useState([]);
  const [employees, setEmployees] = useState([]);

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 9, hide: true },
    {
      field: "name",
      headerName: "Delegación",
      width: 200,
      editable: true,
      flex: 1,
    },
    {
      field: "name_employee",
      headerName: "Responsable_name",
      hide: true,
      width: 200,
      editable: true,
      flex: 1,
    },
    {
      field: "id_employee",
      headerName: "Responsable",
      width: 200,
      editable: true,
      flex: 1,
      renderCell: (params: GridRenderEditCellParams) => {
        const emp: any = employees.find((p: any) => p.id === params.value);
        return <div>{emp ? emp.display_name : ""}</div>;
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return <CustomEditComponent {...params} employees={employees} />;
      },
    },
  ];

  const handleSave = (data: any) => {
    if (data.news.length > 0) {
      handleCreate(data.news);
    }
    if (data.edited.length > 0) {
      handleUpdate(data.edited);
    }
    if (data.removed.length > 0) {
      handleRemove(data.removed);
    }
  };

  useEffect(() => {
    let promises: any = [];
    promises.push(
      getAllDelegations().then((response: any) => {
        setDelegations(response.data);
      })
    );
    promises.push(
      getAllEmployee().then((response: any) => setEmployees(response.data))
    );
    Promise.all(promises).finally(() => setIsLoading(false));
  }, [isLoading]);

  useEffect(() => {
    setIsLoading(true);
  }, [isSaving]);

  const handleCreate = (delegations: any) => {
    delegations.map(async (delegation: any) => {
      const dele = {
        name: delegation.name,
        id_employee: delegation.id_employee,
      };

      setIsSaving(true);

      updateOrCreateDelegations(dele)
        .then(() => setIsLoading(true))
        .catch((error) => console.log(error));
    });
  };

  const handleUpdate = (delegations: any) => {
    delegations.map(async (delegation: any) => {
      const dele = {
        id: delegation.id,
        id_employee: delegation.id_employee,
        name: delegation.name,
      };

      setIsSaving(true);

      updateOrCreateDelegations(dele)
        .then(() => setIsLoading(true))
        .catch((error) => console.log(error));
    });
  };
  const handleRemove = (delegations: any) => {
    delegations.map(async (delegation: any) => {
      setIsSaving(true);

      deleteDelegation(delegation.id)
        .then(() => setIsLoading(true))
        .catch((error) => console.log(error));
    });
  };

  return (
    <>
      {isLoading ? (
        <AedasLoading />
      ) : (
        <>
          <DataGridEditabled
            onlyEditFunct={true}
            rows={delegations}
            columns={columns}
            title={""}
            handleSave={handleSave}
          ></DataGridEditabled>
        </>
      )}
    </>
  );
}

export default Delegation;

function CustomEditComponent(props: any) {
  const { id, field } = props;
  const apiRef = useGridApiContext();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const handleChange = (event: any, newValue: any | null) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue.id });
  };

  return (
    <Autocomplete
      onChange={handleChange}
      disablePortal
      loading={isLoading}
      id="combo-box-demo"
      options={props.employees}
      getOptionLabel={(option: any) => option.display_name}
      sx={{ flex: 1 }}
      renderInput={(params: any) => <TextField {...params} label="" />}
    />
  );
}
