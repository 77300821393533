const initialState = {
  year: 2000,
};
export const yearReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case "@year/add":
      return {
        ...state,
        year: action.payload.year,
      };
    default:
      return state;
  }
};
