import axios from "axios";
import { getDefaultConfigHeader } from "../../Utils/headerDefaultConfigPetition";

export const getAllObjetivesConfig = async () => {
  return await axios.get(
    process.env.REACT_APP_API_BACKEND + "/objectives/config/all",
    getDefaultConfigHeader()
  );
};

export const addObjetivesConfig = async (config: any) => {
  return await axios.post(
    process.env.REACT_APP_API_BACKEND + "/objectives/config",
    config,
    getDefaultConfigHeader()
  );
};

export const getObjectivesByYear = async (id: number, year: string) => {
  return await axios.get(
    process.env.REACT_APP_API_BACKEND +
      "/objectives/config/employee/" +
      id +
      "/" +
      year,
    getDefaultConfigHeader()
  );
};

export const getMyObjectivesByYear = async (id: number, year: string) => {
  return await axios.get(
    process.env.REACT_APP_API_BACKEND +
      "/objectives/config/signed/" +
      id +
      "/" +
      year,
    getDefaultConfigHeader()
  );
};

export const addObjetivesPersonal = async (config: any) => {
  return await axios.post(
    process.env.REACT_APP_API_BACKEND + "/objective/personal",
    config,
    getDefaultConfigHeader()
  );
};

export const removeObjetives = async (id: number) => {
  return await axios.delete(
    process.env.REACT_APP_API_BACKEND + "/objectives?id_objective=" + id,
    getDefaultConfigHeader()
  );
};

export const getPersonalObjectivesByYear = async (year: string) => {
  return await axios.get(
    process.env.REACT_APP_API_BACKEND + "/objetives/personal/2022/" + year,
    getDefaultConfigHeader()
  );
};
