import { Autocomplete, TextField } from "@mui/material";
import {
  GridColDef,
  GridRenderEditCellParams,
  useGridApiContext,
} from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getAllCompany } from "../../../../Service/CompanyService/CompanyService";
import {
  addNewCorpotativeObjectiveCurrentYear,
  getCurrentCorpotativeObjective,
  updateNewCorpotativeObjective,
} from "../../../../Service/CorporativeObjectives/CorporativeObjectivesService";
import { removeObjetives } from "../../../../Service/ObjectiveService/ObjectiveService";
import { sortObj } from "../../../../Utils/integerUtils";
import AedasLoading from "../../../Shared/AedasLoading/AedasLoading";
import DataGridEditabled from "../../../Shared/DataGridEditabled/DataGridEditabled";
import "./Objetives.css";

function Objetives(props: any) {
  const [isLoading, setIsLoading] = useState(true);
  const [company, setCompany] = useState([]);
  const [data, setData] = useState([]);
  const year = useSelector((state: any) => state.yearReducer.year);
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 9, hide: true },
    {
      field: "name",
      headerName: "Objetivo",
      minWidth: 500,
      flex: 1,
      editable: true,
      sortable: false,
    },
    {
      field: "id_company",
      headerName: "Empresa",
      minWidth: 500,
      flex: 1,
      editable: true,
      sortable: false,
      renderCell: (params: GridRenderEditCellParams) => {
        const comp: any = company.find(
          (c: any) => c.id === params.row.id_company
        );
        return <div>{comp ? comp.name : ""}</div>;
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return <CustomEditComponentCompany {...params} company={company} />;
      },
    },
  ];
  useEffect(() => {
    if (isLoading) {
      let promises: any = [];
      promises.push(
        getCurrentCorpotativeObjective(year)
          .then((response: any) => {
            setData(response.data);
          })
          .catch((error) => {
            if (error.request.status === 404) {
            } else {
              toast.error("Ha habido un problema");
            }
          })
      );
      promises.push(
        getAllCompany().then((response: any) => setCompany(response.data))
      );
      Promise.all(promises).finally(() => setIsLoading(false));
    }
  }, [isLoading, year]);

  if (isLoading) {
    return <AedasLoading></AedasLoading>;
  }
  const handleSave = (props: any) => {
    let promisesArray: any = [];
    if (props.news.length > 0) {
      props.news.forEach((objetive: any) => {
        if (objetive.name) {
          promisesArray.push(
            addNewCorpotativeObjectiveCurrentYear(
              objetive.name,
              objetive.id_company
            )
          );
        }
      });
    }
    props.edited.forEach((objetive: any) => {
      if (objetive.name) {
        promisesArray.push(
          updateNewCorpotativeObjective({
            id: objetive.id,
            fromYear: objetive.fromYear,
            id_type: objetive.id_type,
            name: objetive.name,
            id_company: objetive.id_company,
          })
        );
      }
    });

    props.removed.forEach((objetive: any) => {
      promisesArray.push(removeObjetives(objetive.id));
    });
    Promise.all(promisesArray).then(() => setIsLoading(true));
  };
  return (
    <div className="currentObjetives_Table">
      <DataGridEditabled
        onlyEditFunct={true}
        rows={data.sort(sortObj)}
        columns={columns}
        handleSave={handleSave}
      />
    </div>
  );
}

export default Objetives;
function CustomEditComponentCompany(props: any) {
  const { id, field } = props;
  const apiRef = useGridApiContext();

  const handleChange = (event: any, newValue: any | null) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue.id });
  };

  return (
    <Autocomplete
      onChange={handleChange}
      disablePortal
      id="combo-box-demo"
      options={props.company}
      getOptionLabel={(option: any) => option.name}
      sx={{ flex: 1 }}
      renderInput={(params: any) => <TextField {...params} label="" />}
    />
  );
}
