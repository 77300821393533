import { LoadingButton } from "@mui/lab";
import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { validateTemplate } from "../../../../../Service/TemplateService/TemplateService";
import AedasLoading from "../../../../Shared/AedasLoading/AedasLoading";
import "./ValidateModalContent.css";

function ValidateModalContent(props: any) {
  const { selectedRow, setOpen } = props;
  const [isLoadingButton, setisLoadingButton] = useState(false);

  // const team = useSelector((state: any) => state.teamReducer.team);
  const [isLoading, setisLoading] = useState(true);
  const user = useSelector((state: any) => state.userReducer.user);
  const handleValidate = () => {
    setisLoadingButton(true);
    validateTemplate(selectedRow.id_template, user.employee.id)
      .then((response) => {
        toast.success("Validado");
        setisLoadingButton(false);
        setOpen(false);
      })
      .catch(() => {
        toast.error("Ha habido un problema");
        setisLoadingButton(false);
      });
  };
  useEffect(() => {
    setisLoading(false);
  }, [selectedRow]);

  if (isLoading) {
    return <AedasLoading></AedasLoading>;
  }
  return (
    <div className="d-flex justify-content-center">
      <div className="boxValidate">
        <img
          alt="Persona"
          className="imgPersonValidateModal col-3"
          src={process.env.REACT_APP_API_BACKEND + "/" + selectedRow?.url_img}
        ></img>
        <div className="col-9 row p-4">
          <div className="col-12">
            <h1 className="titleValidateModal">
              {selectedRow?.employee_display_name}
            </h1>
          </div>
          <div className="col-12">
            <h2 className="subtitleValidateModal">Manager</h2>
          </div>
          <div className="col-12">
            <span>Confirmo la validación de los objetivos</span>
          </div>
          <div className="col-12 d-flex justify-content-center">
            <LoadingButton
              color="primary"
              onClick={handleValidate}
              className="validate_Button"
              variant="contained"
              fullWidth
              loadingIndicator={<CircularProgress size={16} />}
              loading={isLoadingButton}
              type="submit"
            >
              Mandar a Validar
            </LoadingButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ValidateModalContent;
