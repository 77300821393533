import * as React from "react";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import HomeBox from "../../Shared/HomeBox/HomeBox";
import { useSelector } from "react-redux";
import ShowNotifications from "../../Shared/ShowNotifications/ShowNotifications";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  margin: 20,
  width: 200,
  color: theme.palette.text.secondary,
}));

function HomeExample() {
  const user = useSelector((state: any) => state.userReducer.user);

  return (
    <>
      <div className="d-flex justify-content-center align-items-center h-100">
        <HomeBox>
          <ShowNotifications id_employee={user.employee.id}></ShowNotifications>
        </HomeBox>
      </div>
    </>
  );
}

export default HomeExample;
