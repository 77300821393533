import { GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as yup from "yup";
import {
  createCompany,
  getAllCompany,
  removeCompany,
  updateCompany,
} from "../../../../Service/CompanyService/CompanyService";
import AedasLoading from "../../../Shared/AedasLoading/AedasLoading";
import DataGridEditabled from "../../../Shared/DataGridEditabled/DataGridEditabled";

let schema = yup.object().shape({
  name: yup.string().required(),
});

function Company(props: any) {
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 9, hide: true },
    {
      field: "name",
      headerName: "Empresa",
      width: 200,
      editable: true,
      flex: 1,
    },
    {
      field: "location",
      headerName: "Dirección",
      width: 200,
      editable: true,
      flex: 1,
    },
  ];

  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [companys, setCompanys] = useState([]);

  const handleSave = (data: any) => {
    if (data.news.length > 0) {
      handleCreate(data.news);
    }
    if (data.edited.length > 0) {
      handleEdit(data.edited);
    }
    if (data.removed.length > 0) {
      handleRemove(data.removed);
    }
  };

  useEffect(() => {
    getAllCompany().then((response: any) => {
      setCompanys(response.data);
      setIsLoading(false);
    });
  }, [isLoading]);

  useEffect(() => {
    setIsLoading(true);
  }, [isSaving]);

  const handleCreate = (companys: any) => {
    companys.map(async (company: any) => {
      const result = await schema
        .isValid(company)
        .catch((error) => toast.error("La empresa no puede estar vacia"));
      if (result) {
        setIsSaving(true);
        createCompany(company)
          // .then(() => {toast.success("Actualizado")})
          .then(() => setIsLoading(true))
          .catch((error) => console.log(error));
      }
    });
  };

  const handleEdit = (companys: any) => {
    companys.map(async (company: any) => {
      const result = await schema
        .isValid(company)
        .catch((error) => toast.error("La empresa no puede estar vacia"));
      if (result) {
        setIsSaving(true);
        updateCompany(company)
          // .then(() => {toast.success("Actualizado")})
          .then(() => setIsLoading(true))
          .catch((error) => console.log(error));
      }
    });
  };

  const handleRemove = (companys: any) => {
    companys.map(async (company: any) => {
      const result = await schema
        .isValid(company)
        .catch((error) => toast.error("La empresa no puede estar vacia"));
      if (result) {
        setIsSaving(true);
        removeCompany(company)
          // .then(() => {toast.success("Actualizado")})
          .then(() => setIsLoading(true))
          .catch((error) => console.log(error));
      }
    });
  };

  return (
    <>
      {isLoading ? (
        <AedasLoading />
      ) : (
        <>
          <DataGridEditabled
            onlyEditFunct={true}
            rows={companys}
            columns={columns}
            title={""}
            handleSave={handleSave}
          ></DataGridEditabled>
        </>
      )}
    </>
  );
}

export default Company;
