import {
  DataGrid,
  GridColDef,
  GridRenderEditCellParams,
} from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { getObjectivesByYear } from "../../../../../Service/ObjectiveService/ObjectiveService";
import AedasLoading from "../../../../Shared/AedasLoading/AedasLoading";
import { sortObj, sortObjPersonal } from "../../../../../Utils/integerUtils";
import { Tooltip } from "@mui/material";

function ShowObjetives(props: any) {
  const [isLoading, setisLoading] = useState(true);
  const [data, setData] = useState([]);
  const year = useSelector((state: any) => state.yearReducer.year);
  const [columns, setColums] = useState<GridColDef[]>([
    { field: "id", headerName: "ID", width: 9, hide: true },
    { field: "header", headerName: "Header", width: 9, hide: true },
    {
      field: "objective",
      headerName: "Objetivo",
      minWidth: 350,
      flex: 1,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <Tooltip title={params.value}>
            <div
              style={{
                width: "100%",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {params.value}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: "valoration",
      headerName: "Valoración % s/retirb. fija",
      minWidth: 200,
      flex: 1,
      editable: true,
      sortable: false,
      type: "number",
      renderCell: (params: GridRenderEditCellParams) => {
        return (
          <div>
            {" "}
            {(params.row.valoration ? params.row.valoration + "%" : "").replace(
              ".",
              ","
            )}
          </div>
        );
      },
    },
  ]);
  useEffect(() => {
    if (isLoading && props.modalData) {
      getObjectivesByYear(props.modalData.id_employee, year)
        .then((response) => {
          processData(response.data);
        })
        .catch((error) => console.log(error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, props.modalData, year]);

  const processData = (objectives: any) => {
    let auxArray: any = [];
    let arrayCorporative: any = [];
    let arrayPersonal: any = [];
    let percentageCorporative = 0;
    let percentajePersonal = 0;
    let percentagePErformace_evaluation = 0;
    let auxColums: any = [];

    const getAuxColumns = (percentage: string, value: number) => {
      if (value) {
        auxColums.push({
          field: percentage,
          headerName: percentage + "%",
          minWidth: 110,
          flex: 1,
          editable: true,
          sortable: false,
          hide: value == null ? true : false,
          renderCell: (params: any) => {
            return (
              <Tooltip title={params.value}>
                <div
                  style={{
                    width: "100%",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    textAlign: "center",
                  }}
                >
                  {params.value}
                </div>
              </Tooltip>
            );
          },
        });
      }
    };

    objectives.forEach((obj: any) => {
      if (obj.obj_type === "CORPORATIVE") {
        let corporative: any = [];
        corporative = obj.objectives.map(
          (objectCorporative: any, i: number) => {
            let auxTuple: any = {};
            objectCorporative.percentages.forEach((per: any) => {
              let percentage: string = per[0].toString();
              let value = per[1];
              auxTuple[percentage] = value;

              getAuxColumns(percentage, value);
            });

            return { ...objectCorporative, ...auxTuple };
          }
        );
        arrayCorporative = corporative;
        percentageCorporative = obj.percentage;
      } else if (obj.obj_type === "PERSONAL") {
        let personal: any = [];
        personal = obj.objectives.map((objectCorporative: any) => {
          let auxTuple: any = {};
          objectCorporative.percentages.forEach((per: any) => {
            let percentage: string = per[0].toString();
            let value = per[1];
            auxTuple[percentage] = value;

            getAuxColumns(percentage, value);
          });
          return { ...objectCorporative, ...auxTuple };
        });
        arrayPersonal = personal;
        percentajePersonal = obj.percentage;
      } else if (obj.obj_type === "PERFORMANCE EVALUATION") {
        percentagePErformace_evaluation = obj.percentage;
      }
    });

    auxArray.push({
      id: 0,
      objective: "RESULTADOS CIA (M€)",
      valoration: percentageCorporative,
      header: true,
    });

    auxArray = auxArray.concat(arrayCorporative.sort(sortObj));

    auxArray.push({
      id: 100,
      objective: "OBJETIVO",
      valoration: percentajePersonal,
      header: true,
    });

    auxArray = auxArray.concat(arrayPersonal.sort(sortObjPersonal));

    auxArray.push({
      id: 101,
      objective: "EVALUACIÓN DEL DESEMPEÑO",
      valoration: percentagePErformace_evaluation,
      header: true,
    });

    auxColums.sort((a: any, b: any) => {
      return parseInt(a.field) < parseInt(b.field) ? -1 : 1;
    });

    auxColums = auxColums.filter(function (item: any, pos: any, self: any) {
      return self.findIndex((t: any) => t.field === item.field) === pos;
    });
    setData(auxArray);
    setColums(columns.concat(auxColums));
    setisLoading(false);
  };
  if (isLoading && data.length > 0) {
    return <AedasLoading></AedasLoading>;
  }
  return (
    <>
      <div className="objetives_modal_Table">
        <DataGrid
          density="compact"
          rows={data}
          columns={columns}
          disableSelectionOnClick
          hideFooter
          getRowClassName={(params) => {
            if (params.row.header) {
              return `greyBackground`;
            }
            return "";
          }}
        />
      </div>
    </>
  );
}
export default ShowObjetives;
