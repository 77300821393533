import {
  GridColDef,
  GridRenderEditCellParams,
  useGridApiContext,
} from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getCurrentCorpotativeObjective } from "../../../../../Service/CorporativeObjectives/CorporativeObjectivesService";
import {
  addObjetivesConfig,
  getAllObjetivesConfig,
} from "../../../../../Service/ObjectiveService/ObjectiveService";
import { isInteger } from "../../../../../Utils/integerUtils";
import AedasLoading from "../../../../Shared/AedasLoading/AedasLoading";
import DataGridEditabled from "../../../../Shared/DataGridEditabled/DataGridEditabled";

function RolModal(props: any) {
  const { dataModal } = props;
  const year = useSelector((state: any) => state.yearReducer.year);
  const [data, setData] = useState([]);
  let dataCopy: any = [];
  const [isLoading, setisLoading] = useState(true);
  const [error, setError] = useState(false);
  const [columns, setColums] = useState<GridColDef[]>([
    { field: "id", headerName: "ID", width: 9, hide: true },
    { field: "header", headerName: "Header", width: 9, hide: true },
    {
      field: "objective",
      headerName: "Objetivo",
      minWidth: 350,
      flex: 1,
      editable: false,
      sortable: false,
    },
    {
      field: "valoration",
      headerName: "Valoración % s/retirb. fija",
      minWidth: 200,
      flex: 1,
      editable: true,
      sortable: false,
      type: "number",
      renderCell: (params: GridRenderEditCellParams) => {
        return (
          <div>{params.row.valoration ? params.row.valoration + "%" : ""}</div>
        );
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <CustomPercentageInput
            {...params}
            setError={setError}
            data={dataCopy}
            dataModal={dataModal}
          />
        );
      },
    },
  ]);

  useEffect(() => {
    if (isLoading) {
      let configs: any = [];
      let objectives: any = [];
      getCurrentCorpotativeObjective(year)
        .then((response) => {
          objectives = response.data.filter(
            (obj: any) => obj.id_company === dataModal.id_company
          );
          getAllObjetivesConfig().then((response) => {
            configs = response.data;
            processData(objectives, configs);
          });
        })
        .catch((error) => {
          if (error.request.status === 404) {
            toast.error(error.response.data.detail);
          } else {
            toast.error("Ha habido un problema");
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataModal.id_company, isLoading, year]);

  const checkSave = (props: any) => {
    let count = 0;
    let response = true;
    props.rows.forEach((row: any) => {
      count += parseInt(row.valoration);
    });
    if (count !== dataModal.corporative) {
      toast.error(
        "El porcentaje asignable debe ser de:" + dataModal.corporative + "%"
      );
      return false;
    }
    return response;
  };
  const processData = (objectives: any, configs: any) => {
    let aux: any = [];
    let auxColums: any = [];
    objectives.forEach((obj: any) => {
      const c = configs.find(
        (c: any) =>
          c.id_objective === obj.id &&
          c.id_position_config === dataModal.id_config
      );

      if (c) {
        let auxTuple: any = {};
        c.percentages.forEach((per: any) => {
          let value: string = per[0].toString();
          auxTuple[value] = per[1];
          auxColums.push({
            field: value,
            headerName: value + "%",
            minWidth: 110,
            flex: 1,
            editable: true,
            sortable: false,
          });
        });
        aux.push({
          objective: obj.name,
          id: obj.id,
          valoration: c.valoration ?? null,
          id_config: c.id,
          ...auxTuple,
        });
      } else {
        aux.push({
          objective: obj.name,
          id: obj.id,
          valoration: null,
          id_config: null,
        });
      }
    });
    setData(aux);
    dataCopy = aux;
    setColums(columns.concat(auxColums));
    setisLoading(false);
  };
  const handleSave = (props: any) => {
    let arrayPromieses: any = [];
    props.edited.forEach((o: any) => {
      let tuple: any = [];
      for (var property in o) {
        if (isInteger(property)) {
          tuple.push({
            number: parseInt(property),
            value: o[property],
          });
        }
      }

      const config = {
        objectiveConfig: {
          id_objective: o.id,
          id_position_config: dataModal.id_config,
          id: o.id_config,
          valoration: o.valoration,
        },
        percent_values: tuple.filter((e: any) => e[1] !== null),
      };
      arrayPromieses.push(addObjetivesConfig(config));
    });
    Promise.all(arrayPromieses)
      .then(() => {
        toast.success("Se ha guardado");
        setisLoading(true);
      })
      .catch((error) => {
        toast.error("Ha habido un problema");
      });
  };
  if (isLoading || dataModal === null) {
    return <AedasLoading></AedasLoading>;
  }
  return (
    <>
      <div className="d-flex justify-content-center  w-100">
        <h1 className={!error ? "asignacionTitle" : "asignacionTitleError"}>
          Maxima valoración Asignable {dataModal.corporative ?? 0}%
        </h1>
      </div>
      <div style={{ height: "500px" }}>
        <DataGridEditabled
          onlyEditFunct={true}
          checkSave={checkSave}
          onlyEdit={true}
          rows={data}
          columns={columns}
          handleSave={handleSave}
        />
      </div>{" "}
    </>
  );
}
export default RolModal;

function CustomPercentageInput(props: any) {
  const { id, field } = props;

  const apiRef = useGridApiContext();
  const [error, setError] = useState(false);
  function handleChange(event: any) {
    const row = props.data.find((r: any) => r.id === id);
    if (row) {
      let valoration = 0;
      //let performance_evaluation;
      //let corporative;
      let count = 0;

      if (field === "valoration") {
        valoration = parseInt(event.target.value);
      }

      props.data.forEach((r: any, i: number) => {
        if (r.id !== id) {
          count += parseInt(r.valoration ?? 0);
        }
      });

      if (valoration + count > props.dataModal.corporative) {
        setError(true);
        props.setError(true);
      } else {
        setError(false);
        props.setError(false);
        apiRef.current.setEditCellValue({
          id,
          field,
          value: event.target.value,
        });
      }
    }
  }
  return (
    <input
      onChange={handleChange}
      className={!error ? "inputPercentage" : "inputPercentageError"}
      id="description"
      name="description"
      type="number"
      min="0"
      max="100"
    ></input>
  );
}
