import React, { useEffect, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

import "./SignObjetivesModalContent.css";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { singTemplateByOrder } from "../../../../Service/TemplateService/TemplateService";
import { log } from "console";

function SignObjetivesModalContent(props: any) {
  const { selectedRow, setOpen } = props;
  const user = useSelector((state: any) => state.userReducer.user);
  const [managerSing, setManagerSing] = useState<any>(null);
  const [employeeSing, setEmployeeSing] = useState<any>(null);

  let signRef = useRef<any>();

  const handelSignConfirm = () => {
    singTemplateByOrder(
      selectedRow.id_template,
      managerSing,
      employeeSing,
      selectedRow.id_employee,
      user.employee.id
    )
      .then((reponse) => {
        toast.success("Firmado");
        setOpen(false);
      })
      .catch(() => toast.error("Ha habido un problema"));
  };

  const handelSign = () => {
    const sign = signRef.current.getTrimmedCanvas().toDataURL("image/png");
    if (employeeSing) {
      setManagerSing(sign);
      handleClean();
    } else {
      setEmployeeSing(sign);
      handleClean();
    }
  };

  useEffect(() => {
    if (managerSing) {
      signRef?.current?.clear();
    }
  }, [managerSing]);

  function handleClean() {
    signRef?.current?.clear();
  }

  return (
    <>
      {managerSing && employeeSing ? (
        <div className="row">
          <div className="col-6">
            <img alt="Firma del manager" src={managerSing}></img>
            <h3 style={{ fontSize: "20px" }}>{user.employee.display_name}</h3>
          </div>
          <div className="col-6">
            <img alt="Firma del empleado" src={employeeSing}></img>
            <h3 style={{ fontSize: "20px" }}>
              {selectedRow.employee_display_name}
            </h3>
          </div>
          <Button
            onClick={handelSignConfirm}
            style={{
              backgroundColor: "var(--aedas-green)",
              height: "30px",
            }}
            variant="contained"
            className="sign_button"
          >
            Confirmar firmas
          </Button>
        </div>
      ) : (
        <div className="d-flex justify-content-center row">
          <div className="boxSign">
            {/* <img
          className="imgPersonSignModal col-3"
          src={process.env.REACT_APP_API_BACKEND + "/" + user.employee.url_img}
        ></img> */}
            <div className="col-9 row p-4">
              <div className="col-12">
                <h1 className="titleSignModal">
                  {!employeeSing
                    ? selectedRow.employee_display_name
                    : user.employee.display_name}
                </h1>
              </div>
              <div className="col-12">
                {employeeSing ? (
                  <span>
                    Confirmo que he estado reunido con mi empleado y se ha
                    llegado a un acuerdo por los objetivos de este año.
                  </span>
                ) : (
                  <span>
                    Confirmo que he estado reunido con mi manager y se ha
                    llegado a un acuerdo por los objetivos de este año.
                  </span>
                )}
              </div>
              <div className="col-12 d-flex justify-content-center"></div>
            </div>
          </div>
          <div className="col-12 d-flex justify-content-center row">
            <div className=" d-flex justify-content-between toolbar pt-1">
              <div></div>
              <h3 className="sign_header ">Firma</h3>
              <IconButton onClick={handleClean}>
                <DeleteIcon></DeleteIcon>
              </IconButton>
            </div>

            <div className="col-12 d-flex justify-content-center">
              <SignatureCanvas
                ref={signRef}
                penColor="blue"
                canvasProps={{
                  width: 500,
                  height: 200,
                  className: "sigCanvas",
                }}
              />
            </div>
            <Button
              onClick={handelSign}
              style={{
                backgroundColor: "var(--aedas-green)",
                height: "30px",
              }}
              variant="contained"
              className="sign_button"
            >
              Firmar
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

export default SignObjetivesModalContent;
