import axios from "axios";
import { getDefaultConfigHeader } from "../../Utils/headerDefaultConfigPetition";

export const getTeam = async (id_manager: number, token: any = null) => {
  return await axios.get(
    process.env.REACT_APP_API_BACKEND + "/team?id_manager=" + id_manager,
    getDefaultConfigHeader()
  );
};

export const getTeamSelf = async (id: number) => {
  return await axios.get(
    process.env.REACT_APP_API_BACKEND + "/team/self?id_employee=" + id,
    getDefaultConfigHeader()
  );
};
