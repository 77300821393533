import axios from "axios";
import { getDefaultConfigHeader } from "../../Utils/headerDefaultConfigPetition";

export const getAllDelegations = () => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/delegacion/all",
    getDefaultConfigHeader()
  );
};

export const updateOrCreateDelegations = (delegation: any) => {
  return axios.post(
    process.env.REACT_APP_API_BACKEND + "/delegacion/",
    delegation,
    getDefaultConfigHeader()
  );
};

export const deleteDelegation = (id_delegation: number) => {
  return axios.post(
    process.env.REACT_APP_API_BACKEND +
      "/delegacion/delete?id_delegacion=" +
      id_delegation,
    {},
    getDefaultConfigHeader()
  );
};
