import moment from "moment";
import React, { useEffect, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import CompanySelector from "../../Shared/CompanySelector/CompanySelector";
import Company from "./Company/Company";
import CostCenter from "./CostCenter/CostCenter";
import Delegation from "./Delegation/Delegation";
import EmployeesDesactive from "./EmployeeDesactive/EmployeeDesactive";
import Employees from "./Employees/Employees";
import Organigrama from "./Organigrama/Organigrama";
import Rols from "./Position/Position";

function EmployeeManagementMain(props: any) {
  const [date, setDate] = useState(moment().toISOString());
  const [company, setCompany] = useState({ id: 1, name: "AEDAS Homes" });

  return (
    <div className="px-4 h-100">
      <h1 className="titleTeams">Gestión de empleados</h1>

      <Tabs>
        <TabList>
          <Tab>Organigrama</Tab>
          <Tab>Empleados</Tab>
          <Tab>Activación</Tab>
          <Tab>Empresas</Tab>
          <Tab>Roles</Tab>
          <Tab>Delegaciones</Tab>
          <Tab>Centro de costes</Tab>
        </TabList>

        <TabPanel>
          <div className="w-00 d-flex justify-content-center">
            <CompanySelector
              setCompany={setCompany}
              company={company}
            ></CompanySelector>
          </div>
          <Organigrama company={company}></Organigrama>
        </TabPanel>
        <TabPanel>
          <Employees date={date}></Employees>
        </TabPanel>
        <TabPanel>
          <EmployeesDesactive></EmployeesDesactive>
        </TabPanel>
        <TabPanel>
          <Company></Company>
        </TabPanel>
        <TabPanel>
          <Rols></Rols>
        </TabPanel>
        <TabPanel>
          <Delegation></Delegation>
        </TabPanel>
        <TabPanel>
          <CostCenter></CostCenter>
        </TabPanel>
      </Tabs>
    </div>
  );
}

export default EmployeeManagementMain;
