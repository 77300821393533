import { Autocomplete, TextField } from "@mui/material";
import {
  GridColDef,
  GridRenderEditCellParams,
  useGridApiContext,
} from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as yup from "yup";
import { getAllCompany } from "../../../../Service/CompanyService/CompanyService";
import {
  addPosition,
  deletePosition,
  getAllPositions,
  updatePosition,
} from "../../../../Service/DepartmentService/PositionService";
import AedasLoading from "../../../Shared/AedasLoading/AedasLoading";
import DataGridEditabled from "../../../Shared/DataGridEditabled/DataGridEditabled";

let schema = yup.object().shape({
  name: yup.string().required(),
});

function Position(props: any) {
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const [rols, setRols] = useState([]);
  const [company, setCompany] = useState([]);
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 9, hide: true },
    {
      field: "name",
      headerName: "Rol",
      width: 200,
      editable: true,
      flex: 1,
    },
    {
      field: "id_company",
      headerName: "Empresa",
      width: 200,
      editable: true,
      flex: 1,
      renderCell: (params: GridRenderEditCellParams) => {
        const emp: any = company.find(
          (p: any) => p.id === params.row.id_company
        );
        return <div>{emp ? emp.name : ""}</div>;
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return <CustomEditComponentCompany {...params} company={company} />;
      },
    },
    {
      field: "porcentaje",
      headerName: "Retribución",
      width: 200,
      editable: true,
      flex: 1,
      renderCell: (params: GridRenderEditCellParams) => {
        return (
          <div>{params.row.porcentaje ? params.row.porcentaje + "" : ""}</div>
        );
      },
    },
  ];

  const handleSave = (data: any) => {
    if (data.news.length > 0) {
      handleCreate(data.news);
    }
    if (data.edited.length > 0) {
      handleEdit(data.edited);
    }
    if (data.removed.length > 0) {
      handleRemove(data.removed);
    }
  };

  useEffect(() => {
    let promises: any = [];
    promises.push(
      getAllPositions().then((response: any) => {
        setRols(response.data);
      })
    );
    promises.push(
      getAllCompany().then((response: any) => setCompany(response.data))
    );
    Promise.all(promises).finally(() => setIsLoading(false));
  }, [isLoading]);

  useEffect(() => {
    setIsLoading(true);
  }, [isSaving]);

  const handleCreate = (roles: any) => {
    roles.map(async (rol: any) => {
      const result = await schema
        .isValid(rol)
        .catch((error) => toast.error("La empresa no puede estar vacia"));

      if (result) {
        setIsSaving(true);
        addPosition(rol.name, rol.id_company, rol.porcentaje)
          .then(() => setIsLoading(true))
          .catch((error) => console.log(error));
      }
    });
  };

  const handleEdit = (roles: any) => {
    roles.map(async (rol: any) => {
      const result = await schema
        .isValid(rol)
        .catch((error) => toast.error("La empresa no puede estar vacia"));

      if (result) {
        setIsSaving(true);

        updatePosition(rol.name, rol.id, rol.id_company, rol.porcentaje)
          .then(() => setIsLoading(true))
          .catch((error) => console.log(error));
      }
    });
  };

  const handleRemove = (roles: any) => {
    roles.map(async (rol: any) => {
      const result = await schema
        .isValid(rol)
        .catch((error) => toast.error("La empresa no puede estar vacia"));
      if (result) {
        setIsSaving(true);

        deletePosition(rol.id)
          .then(() => setIsLoading(true))
          .catch((error) => console.log(error));
      }
    });
  };

  return (
    <>
      {isLoading ? (
        <AedasLoading />
      ) : (
        <>
          <DataGridEditabled
            onlyEditFunct={true}
            rows={rols}
            columns={columns}
            title={""}
            handleSave={handleSave}
          ></DataGridEditabled>
        </>
      )}
    </>
  );
}

export default Position;
function CustomEditComponentCompany(props: any) {
  const { id, field } = props;
  const apiRef = useGridApiContext();

  const handleChange = (event: any, newValue: any | null) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue.id });
  };

  return (
    <Autocomplete
      onChange={handleChange}
      disablePortal
      id="combo-box-demo"
      options={props.company}
      getOptionLabel={(option: any) => option.name}
      sx={{ flex: 1 }}
      renderInput={(params: any) => <TextField {...params} label="" />}
    />
  );
}
