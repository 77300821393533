import { IPublicClientApplication } from "@azure/msal-browser";
import { MsalProvider, useIsAuthenticated, useMsal } from "@azure/msal-react";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import BarChartIcon from "@mui/icons-material/BarChart";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupsIcon from "@mui/icons-material/Groups";
import HomeIcon from "@mui/icons-material/Home";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { loginRequest } from "./authConfig";
import AedasLoading from "./Components/Shared/AedasLoading/AedasLoading";
import Header from "./Components/Shared/Header/Header";
import BusinessManager from "./Components/Static/BusinessManager/BusinessManager";
import CurrentYear from "./Components/Static/CurrentYear/CurrentYear";
import EmployeeManagementMain from "./Components/Static/EmployeeManagement/EmployeeManagementMain";
import EmployeeNotifications from "./Components/Static/EmployeeNotifications/EmployeeNotifications";
import HomeExample from "./Components/Static/Home/HomeExample";
import Login from "./Components/Static/Login/Login";
import MyObjetives from "./Components/Static/MyObjetives/MyObjetives";
import NextYear from "./Components/Static/NextYear/NextYear";
import RrccValidation from "./Components/Static/RrccValidation/RrccValidation";
import SignatureByOrder from "./Components/Static/SignatureByOrder/SignatureByOrder";
import Statistics from "./Components/Static/statistics/statistics";
import SteeringCommitteeMain from "./Components/Static/SteeringCommittee/SteeringCommittee";
import TeamMain from "./Components/Static/teams/teamsMain";
import TemplateStatus from "./Components/Static/TemplatesStatus/TemplateStatus";
import { setTeam } from "./Redux/Team/TeamAction";
import { userLogin } from "./Redux/User/UserAction";
import { newYear } from "./Redux/Year/YearAction";
import { loginAzure } from "./Service/AuthService/AuthService";
import { getTeam } from "./Service/TeamService/TeamService";
import { getYear } from "./Service/YearService/YearService";
import { getCurrentPeriod, getNextPeriod } from "./Utils/aedasDateUtil";
import { CustomNavigationClient } from "./Utils/NavigationClient";
import { validatePermission } from "./Utils/permissionsUtils";

type AppProps = {
  pca: IPublicClientApplication;
};
function App({ pca }: AppProps) {
  const history = useHistory();
  const navigationClient = new CustomNavigationClient(history);
  pca.setNavigationClient(navigationClient);

  return (
    <>
      <ToastContainer autoClose={1500}></ToastContainer>
      <MsalProvider instance={pca}>
        <Router></Router>
      </MsalProvider>
    </>
  );
}

export default App;

const Router = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const isLogin = useSelector((state: any) => state.userReducer.isLogin);
  const user = useSelector((state: any) => state.userReducer.user);

  const year = useSelector((state: any) => state.yearReducer.year);
  const isManager = useSelector((state: any) => state.teamReducer.isManager);
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();
  const { instance, accounts, inProgress } = useMsal();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLogin) {
      getTeam(user.employee.id)
        .then((response) => {
          dispatch(setTeam(response.data));
          setIsLoading(false);
          history.push(location.pathname + location.search ?? "");
        })
        .catch(() => {
          setIsLoading(false);
          history.push(location.pathname);
        });

      //history.push(location.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogin, user]);

  useEffect(() => {
    const fetchDate = async () => {
      let response = await getYear();
      dispatch(newYear(response.data.year));
    };
    fetchDate();
  });
  useEffect(() => {
    if (isAuthenticated && inProgress === "none") {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };
      instance
        .acquireTokenSilent(request)
        .then(async (response) => {
          const responseBackend: any = await loginAzure(
            response.accessToken
          ).catch((error) => console.log("ERROR"));
          if (responseBackend) {
            getTeam(
              responseBackend.data.employee.id,
              responseBackend.data.token
            ).then((response) => {
              dispatch(setTeam(response.data));
            });

            // let position: string = responsePositions.data.find(
            //   (pos: any) =>
            //     pos.id === responseBackend.data.employee.id_department
            // ).name;

            let position: string = "Por defecto";
            localStorage.setItem(
              "aedas_user",
              JSON.stringify(responseBackend.data)
            );
            dispatch(
              userLogin(responseBackend.data, response.accessToken, position)
            );
          } else {
            toast.error("No tienes permiso");
            history.push("/login");
            setIsLoading(false);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else if (!isAuthenticated && inProgress === "none") {
      instance
        .loginRedirect(loginRequest)
        .then((response) => console.log("login", response))
        .catch((e) => {
          console.error(e);
        });
    } else if (inProgress === "none") {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, inProgress]);

  const menu: any = [
    {
      name: "Home",
      localPath: "/",
      icon: <HomeIcon />,
      onClick: history.push,
      canView: true,
      shortName: "Home",
      hasPermissions: true,
    },
    {
      name: "Objetivos RRCC",
      localPath: "/objetivos-empresa",
      icon: <DashboardIcon />,
      shortName: "RRCC",
      onClick: history.push,
      canView: validatePermission(
        user ? user.permissions : [],
        "app_objectives.rrcc.view"
      ),

      children: [
        {
          name: "Objetivos " + getCurrentPeriod(year),
          localPath: "/objetivos-empresa/actual",
          icon: <CalendarTodayIcon />,
          onClick: history.push,
          canView: validatePermission(
            user ? user.permissions : [],
            "app_objectives.rrcc.all"
          ),
        },
        {
          name: "Objetivos " + getNextPeriod(year),
          localPath: "/objetivos-empresa/proximo",
          icon: <CalendarTodayIcon />,
          onClick: history.push,
          canView: validatePermission(
            user ? user.permissions : [],
            "app_objectives.rrcc.all"
          ),
        },
        {
          name: "Estados",
          localPath: "/estados",
          icon: <PendingActionsIcon />,
          shortName: "Estado",
          onClick: history.push,
          canView: validatePermission(
            user ? user.permissions : [],
            "app_objectives.rrcc.all"
          ),
        },
        {
          name: "Firmar por orden",
          localPath: "/firmar-por-orden",
          icon: <BorderColorIcon />,
          shortName: "Por orden",
          onClick: history.push,
          canView: validatePermission(
            user ? user.permissions : [],
            "app_objectives.signByOrder.all"
          ),
        },
        {
          name: "Res. negocio",
          localPath: "/responsable-de-negocio",
          icon: <CoPresentIcon />,
          shortName: "Por orden",
          onClick: history.push,
          canView: validatePermission(
            user ? user.permissions : [],
            "app_objectives.seeValidateDirectorNegocio.all"
          ),
        },
        {
          name: "Objetivos C.Dirección",
          localPath: "/comite-direccion",
          icon: <CoPresentIcon />,
          shortName: "O.C.D",
          onClick: history.push,
          canView: validatePermission(
            user ? user.permissions : [],
            "app_objectives.steeringCommittee.all"
          ),
        },
        {
          name: "Validación RRCC",
          localPath: "/recursos-corporativos-validacion",
          icon: <CoPresentIcon />,
          shortName: "RRCC",
          onClick: history.push,
          canView: validatePermission(
            user ? user.permissions : [],
            "app_objectives.rrcc.all"
          ),
        },
        // {
        //   name: "Historico",
        //   localPath: "/historico",
        //   icon: <MenuBookIcon />,
        //   onClick: history.push,
        //   canView: true,
        // },
      ],
    },

    {
      name: "Mis Objetivos",
      localPath: "/mis-objetivos",
      icon: <AssignmentTurnedInIcon />,
      onClick: history.push,
      shortName: "Mis Obj",
      canView: validatePermission(
        user ? user.permissions : [],
        "app_objectives.my_objective.all"
      ),
    },
    {
      name: "Mi Equipo",
      localPath: "/equipo",
      shortName: "Equipo",
      icon: <GroupsIcon />,
      onClick: history.push,
      canView:
        validatePermission(
          user ? user.permissions : [],
          "app_objectives.team.all"
        ) && isManager,
    },
    {
      name: "Gestión de empleados",
      localPath: "/gestion-empleados",
      icon: <AssignmentIndIcon />,
      shortName: "Gestión",
      onClick: history.push,
      canView: validatePermission(
        user ? user.permissions : [],
        "app_objectives.employee_manager.all"
      ),
    },
    {
      name: "Estadisticas",
      localPath: "/estadisticas",
      icon: <BarChartIcon />,
      shortName: "Datos",
      onClick: history.push,
      canView: validatePermission(
        user ? user.permissions : [],
        "app_objectives.statistics.all"
      ),
    },
    {
      name: "Notificaciones",
      localPath: "/notificaciones",
      icon: <NotificationsIcon />,
      shortName: "Avisos",
      onClick: history.push,
      canView: validatePermission(
        user ? user.permissions : [],
        "app_objectives.notifications.all"
      ),
    },
  ];
  if (isLoading) {
    return (
      <div className="w-100 h-100 d-flex justify-content-center">
        <AedasLoading></AedasLoading>
      </div>
    );
  }
  return (
    <>
      <div className="row" style={{ height: "100vh" }}>
        {isLogin ? (
          <Header menu={menu} open={isOpen} setIsOpen={setIsOpen}></Header>
        ) : (
          <></>
        )}

        <div
          style={
            isLogin ? { flex: 1, marginLeft: isOpen ? "300px" : "70px" } : {}
          }
          className="w-100"
        >
          <Switch>
            <Route exact path="/login">
              <Login isLogin={isLogin}></Login>
            </Route>
            <PrivateRoute auth={isLogin} hasPermissions={true} exact path="/">
              <HomeExample></HomeExample>
            </PrivateRoute>
            <PrivateRoute
              auth={isLogin}
              hasPermissions={validatePermission(
                user ? user.permissions : [],
                "app_objectives.rrcc.view"
              )}
              exact
              path="/objetivos-empresa"
            >
              <div>
                <h1>This is example page</h1>
              </div>
            </PrivateRoute>
            <PrivateRoute
              auth={isLogin}
              exact
              hasPermissions={
                validatePermission(
                  user ? user.permissions : [],
                  "app_objectives.team.all"
                ) && isManager
              }
              path="/equipo"
            >
              <TeamMain></TeamMain>
            </PrivateRoute>
            <PrivateRoute
              auth={isLogin}
              exact
              hasPermissions={validatePermission(
                user ? user.permissions : [],
                "app_objectives.rrcc.all"
              )}
              path="/estados"
            >
              <TemplateStatus></TemplateStatus>
            </PrivateRoute>
            <PrivateRoute
              auth={isLogin}
              hasPermissions={validatePermission(
                user ? user.permissions : [],
                "app_objectives.my_objective.all"
              )}
              exact
              path="/mis-objetivos"
            >
              <MyObjetives></MyObjetives>
            </PrivateRoute>
            <PrivateRoute
              auth={isLogin}
              hasPermissions={validatePermission(
                user ? user.permissions : [],
                "app_objectives.employee_manager.all"
              )}
              exact
              path="/gestion-empleados"
            >
              <EmployeeManagementMain></EmployeeManagementMain>
            </PrivateRoute>
            <PrivateRoute
              auth={isLogin}
              hasPermissions={validatePermission(
                user ? user.permissions : [],
                "app_objectives.rrcc.all"
              )}
              exact
              path="/recursos-corporativos-validacion"
            >
              <RrccValidation></RrccValidation>
            </PrivateRoute>
            <PrivateRoute
              auth={isLogin}
              hasPermissions={validatePermission(
                user ? user.permissions : [],
                "app_objectives.statistics.all"
              )}
              exact
              path="/estadisticas"
            >
              <Statistics></Statistics>
            </PrivateRoute>
            <PrivateRoute
              auth={isLogin}
              hasPermissions={validatePermission(
                user ? user.permissions : [],
                "app_objectives.steeringCommittee.all"
              )}
              exact
              path="/comite-direccion"
            >
              <SteeringCommitteeMain></SteeringCommitteeMain>
            </PrivateRoute>
            <PrivateRoute
              auth={isLogin}
              hasPermissions={validatePermission(
                user ? user.permissions : [],
                "app_objectives.signByOrder.all"
              )}
              exact
              path="/firmar-por-orden"
            >
              <SignatureByOrder></SignatureByOrder>
            </PrivateRoute>
            <PrivateRoute
              auth={isLogin}
              hasPermissions={validatePermission(
                user ? user.permissions : [],
                "app_objectives.notifications.all"
              )}
              exact
              path="/notificaciones"
            >
              <EmployeeNotifications></EmployeeNotifications>
            </PrivateRoute>
            <PrivateRoute
              auth={isLogin}
              hasPermissions={
                validatePermission(
                  user ? user.permissions : [],
                  "app_objectives.seeValidateDirectorNegocio.all"
                ) || true
              }
              exact
              path="/responsable-de-negocio"
            >
              <BusinessManager></BusinessManager>
            </PrivateRoute>
            {/* <PrivateRoute auth={false} exact path="/historico">
              <Historic></Historic>
            </PrivateRoute> */}
            <PrivateRoute
              auth={isLogin}
              hasPermissions={validatePermission(
                user ? user.permissions : [],
                "app_objectives.rrcc.all"
              )}
              exact
              path="/objetivos-empresa/actual"
            >
              <CurrentYear></CurrentYear>
            </PrivateRoute>
            <PrivateRoute
              auth={isLogin}
              exact
              hasPermissions={validatePermission(
                user ? user.permissions : [],
                "app_objectives.rrcc.all"
              )}
              path="/objetivos-empresa/proximo"
            >
              <NextYear></NextYear>
            </PrivateRoute>
          </Switch>
        </div>
      </div>
    </>
  );
};
const PrivateRoute = (props: any) => {
  if (props.auth) {
    if (props.hasPermissions) {
      return <Route {...props}></Route>;
    } else {
      return <Redirect to="/" />;
    }
  } else {
    return <Redirect to="/login" />;
  }
};
