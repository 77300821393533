import {
  GridCellParams,
  GridColDef,
  GridRenderEditCellParams,
  MuiEvent,
  useGridApiContext,
} from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getAllCompany } from "../../../../Service/CompanyService/CompanyService";
import {
  getAllPositions,
  getCurrentPositionConfig,
  newPositionConfigCurrent,
} from "../../../../Service/DepartmentService/PositionService";
import AedasLoading from "../../../Shared/AedasLoading/AedasLoading";
import DataGridEditabled from "../../../Shared/DataGridEditabled/DataGridEditabled";
import CustomModal from "../../../Shared/ModalCustom/CustomModal";
import "./Roles.css";
import RolModal from "./RolModal/RolModal";

function Roles(props: any) {
  const [data, setData] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const year = useSelector((state: any) => state.yearReducer.year);
  const [openModalShow, setopenModalShow] = useState(false);
  const [dataModal, setDataModal] = useState<any>(null);
  const [company, setCompany] = useState([]);

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 9, hide: true },
    {
      field: "name",
      headerName: "Rol",
      width: 350,
      sortable: false,
      editable: false,
      flex: 1,
    },
    {
      field: "id_company",
      headerName: "Empresa",
      width: 350,
      sortable: false,
      editable: false,
      flex: 1,
      renderCell: (params: GridRenderEditCellParams) => {
        const comp: any = company.find(
          (c: any) => c.id === params.row.id_company
        );
        return <div>{comp ? comp.name : ""}</div>;
      },
    },
    {
      field: "corporative",
      headerName: "Objetivos corporativos",
      width: 300,
      sortable: false,
      flex: 1,

      editable: true,
      renderCell: (params: GridRenderEditCellParams) => {
        return (
          <div>
            {params.row.corporative ? params.row.corporative + "%" : ""}
          </div>
        );
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return <CustomPercentageInput {...params} data={data} />;
      },
    },
    {
      field: "personal",
      headerName: "Objetivos personales",
      width: 300,
      renderCell: (params: GridRenderEditCellParams) => {
        return (
          <div> {params.row.personal ? params.row.personal + "%" : ""}</div>
        );
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return <CustomPercentageInput {...params} data={data} />;
      },
      sortable: false,
      editable: true,
      flex: 1,
    },

    {
      field: "performance_evaluation",
      headerName: "Evaluación el desempeño",
      width: 300,
      renderCell: (params: GridRenderEditCellParams) => {
        return (
          <div>
            {params.row.performance_evaluation
              ? params.row.performance_evaluation + "%"
              : ""}
          </div>
        );
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return <CustomPercentageInput {...params} data={data} />;
      },
      sortable: false,
      editable: true,
      flex: 1,
    },
  ];
  useEffect(() => {
    if (dataModal) {
      setopenModalShow(true);
    }
  }, [dataModal]);

  useEffect(() => {
    if (!openModalShow) {
      setDataModal(null);
    }
  }, [openModalShow]);
  useEffect(() => {
    if (isLoading && data.length > 0) {
      setisLoading(false);
    }
  }, [data, isLoading]);

  useEffect(() => {
    if (isLoading) {
      let positions: any = [];
      let configs: any = [];
      getAllPositions()
        .then((response: any) => {
          positions = response.data;
          getAllCompany().then((responseComp: any) =>
            setCompany(responseComp.data)
          );
          getCurrentPositionConfig()
            .then((response: any) => {
              configs = response.data;
              processData(positions, configs);
            })
            .catch(() => processData(positions, configs));
        })
        .catch((error) => toast.error("Ha habido un problema"));
    }
  }, [isLoading]);

  const processData = (positions: any, configs: any) => {
    let finalData: any = [];
    positions.forEach((position: any) => {
      let p = configs.find((c: any) => c.id_position === position.id);
      if (p) {
        finalData.push({
          id: position.id,
          name: position.name,
          personal: p.personal,
          performance_evaluation: p.performance_evaluation,
          corporative: p.corporative,
          id_config: p.id,
          id_company: position.id_company,
        });
      } else {
        finalData.push({
          id: position.id,
          id_config: null,
          name: position.name,
          personal: null,
          performance_evaluation: null,
          corporative: null,
          id_company: position.id_company,
        });
      }
    });
    setData(finalData);
  };
  const checkSave = (props: any) => {
    let response = true;
    props.edited.forEach((rol: any) => {
      let personal = parseInt(rol.personal ?? 0);
      let performance_evaluation = parseInt(rol.performance_evaluation ?? 0);
      let corporative = parseInt(rol.corporative ?? 0);
      if (performance_evaluation + corporative + personal !== 100) {
        response = false;
      }
    });
    if (!response) {
      toast.error("Los porcentajes deben sumar 100%");
    }
    return response;
  };
  const handleSave = (props: any) => {
    let promisesArray: any = [];
    props.edited.forEach((rol: any) => {
      let personal = parseInt(rol.personal ?? 0);
      let performance_evaluation = parseInt(rol.performance_evaluation ?? 0);
      let corporative = parseInt(rol.corporative ?? 0);
      if (performance_evaluation + corporative + personal > 100) {
        toast.error("Se ha superado el 100%");
      } else {
        promisesArray.push(
          newPositionConfigCurrent(
            {
              id: rol.id_config,
              id_position: rol.id,
              personal: rol.personal,
              performance_evaluation: rol.performance_evaluation,
              corporative: rol.corporative,
            },
            year
          )
        );
      }
    });
    if (promisesArray.length > 0) {
      Promise.all(promisesArray)
        .then(() => {
          toast.success("Guardado");
          setisLoading(true);
        })
        .catch(() => toast.error("Ha habido un problema"));
    }
  };
  if (isLoading) {
    return <AedasLoading></AedasLoading>;
  }

  return (
    <>
      <CustomModal
        size="xl"
        show={openModalShow}
        onChange={() => {
          setopenModalShow(!openModalShow);
        }}
        title={"Porcentaje rol " + dataModal ? dataModal?.name : ""}
        body={<RolModal dataModal={dataModal}></RolModal>}
      ></CustomModal>
      <div className="currentObjetives_Table">
        <DataGridEditabled
          checkSave={checkSave}
          onCellClick={(
            params: GridCellParams,
            event: MuiEvent<React.MouseEvent>
          ) => {
            setDataModal(params.row);
          }}
          handleSave={handleSave}
          onlyEdit={true}
          rows={data}
          columns={columns}
        />
      </div>
    </>
  );
}

export default Roles;

function CustomPercentageInput(props: any) {
  const { id, field } = props;
  const apiRef = useGridApiContext();
  const [error, setError] = useState(false);

  function handleChange(event: any) {
    const row = props.data.find((r: any) => r.id === id);
    if (row) {
      let personal;
      let performance_evaluation;
      let corporative;
      if (field === "personal") {
        personal = parseInt(event.target.value);
      } else {
        personal = parseInt(row.personal ?? 0);
      }
      if (field === "performance_evaluation") {
        performance_evaluation = parseInt(event.target.value);
      } else {
        performance_evaluation = parseInt(row.performance_evaluation ?? 0);
      }
      if (field === "corporative") {
        corporative = parseInt(event.target.value);
      } else {
        corporative = parseInt(row.corporative ?? 0);
      }
      if (personal + performance_evaluation + corporative > 100) {
        setError(true);
      } else {
        setError(false);
        apiRef.current.setEditCellValue({
          id,
          field,
          value: event.target.value,
        });
      }
    }
  }
  return (
    <input
      onChange={handleChange}
      className={!error ? "inputPercentage" : "inputPercentageError"}
      id="description"
      name="description"
      type="number"
      min="0"
      max="100"
    ></input>
  );
}
