import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import React from "react";
import { Modal } from "react-bootstrap";
import "./CustomModal.css";

export default function CustomModal(props: any) {
  const { body, size, show, onChange, title } = props;
  return (
    <Modal
      size={size ?? "md"}
      show={show}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header className=" modalHeader raleway-bold-white-15px">
        <Modal.Title>{title}</Modal.Title>
        <IconButton style={{ color: "white" }} onClick={() => onChange(!show)}>
          <CloseIcon></CloseIcon>
        </IconButton>
      </Modal.Header>

      {show ? <Modal.Body>{body}</Modal.Body> : <></>}
    </Modal>
  );
}
