import { GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as yup from "yup";
import {
  createCostCenter,
  getAllCostsCenter,
  removeCostCenter,
  updateCostCenter,
} from "../../../../Service/CostCenter/CostCenter";
import AedasLoading from "../../../Shared/AedasLoading/AedasLoading";
import DataGridEditabled from "../../../Shared/DataGridEditabled/DataGridEditabled";

let schema = yup.object().shape({
  name: yup.string().trim().required(),
});

const CostCenter = (props: any) => {
  const [isLoading, setIsLoading] = useState<Boolean>(true);
  const [isSaving, setIsSaving] = useState<Boolean>(false);
  const [costsCenter, setCostsCenters] = useState<any>([]);

  useEffect(() => {
    getAllCostsCenter().then((response: any) => {
      setCostsCenters(response.data);
      setIsLoading(false);
    });
  }, [isLoading]);

  useEffect(() => {
    setIsLoading(true);
  }, [isSaving]);

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 9, hide: true },
    {
      field: "name",
      headerName: "Nombre",
      editable: true,
      flex: 1,
    },
  ];

  const handleSave = (data: any) => {
    if (data.news.length > 0) {
      handleCreate(data.news);
    }
    if (data.edited.length > 0) {
      handleEdit(data.edited);
    }
    if (data.removed.length > 0) {
      handleRemove(data.removed);
    }
  };

  const handleCreate = (costCenter: any) => {
    costCenter.map(async (costCenter: any) => {
      const result = await schema
        .isValid(costCenter)
        .catch((error) => toast.error("El centro de coste debe tener datos"));
      if (result) {
        setIsSaving(true);
        createCostCenter(costCenter)
          .then(() => setIsLoading(true))
          .catch((error) => console.log(error));
      }
    });
  };

  const handleEdit = (costCenter: any) => {
    costCenter.map(async (costCenter: any) => {
      const result = await schema
        .isValid(costCenter)
        .catch((error) => toast.error("El centro de coste debe tener datos"));
      if (result) {
        setIsSaving(true);
        updateCostCenter(costCenter)
          .then(() => setIsLoading(true))
          .catch((error) => console.log(error));
      }
    });
  };

  const handleRemove = (costCenter: any) => {
    costCenter.map(async (costCenter: any) => {
      const result = await schema
        .isValid(costCenter)
        .catch((error) =>
          toast.error("Se debe seleccionar al menos un centro de coste")
        );
      if (result) {
        setIsSaving(true);
        removeCostCenter(costCenter)
          .then(() => setIsLoading(true))
          .catch((error) => console.log(error));
      }
    });
  };

  return (
    <>
      {isLoading ? (
        <AedasLoading />
      ) : (
        <DataGridEditabled
          onlyEditFunct={true}
          rows={costsCenter}
          columns={columns}
          title={""}
          handleSave={handleSave}
        ></DataGridEditabled>
      )}
    </>
  );
};

export default CostCenter;
