import axios from "axios";
import { getDefaultConfigHeader } from "../../Utils/headerDefaultConfigPetition";

export const publishNextYear = async () => {
  return axios.post(
    process.env.REACT_APP_API_BACKEND + "/years/publish",
    {},
    getDefaultConfigHeader()
  );
};
