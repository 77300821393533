import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { toast } from "react-toastify";
import {
  getCurrentPerformance,
  getCurrentStates,
  getCurrentStatesByDeparment,
} from "../../../Service/StatisticsService/StatisticsService";
import AedasLoading from "../../Shared/AedasLoading/AedasLoading";
import BoxStatistics from "../../Shared/BoxStatistics/BoxStatistics";
import "./statistics.css";

function Statistics(props: any) {
  const [dataStates, setdataStates] = useState([]);
  const [dataStatesChart, setdataStatesChart] = useState([]);
  const [dataDepartmentChart, setDataDepartmentChart] = useState([]);
  const [dataDepartmentCategories, setDataDepartmentCategories] = useState([]);
  const [dataPerformance, setDataPerformance] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [haveStatistics, setHaveStatistics] = useState(false);

  const pieChart: any = {
    options: {
      chart: {
        id: "basic-bar",
      },
      legend: {
        position: "bottom",
      },
      labels: [
        "Pendiente",
        "Validando",
        "Esperando Reunion",
        "Firmado",
        "Rechazado",
        "Evaluación de Desempeño",
      ],

      colors: ["#BBBCBC", "#041C2C", "#88DBDF", "#00B176", "#FF7E31", "F93E3E"],
    },
  };

  const barChart: any = {
    options: {
      chart: {
        id: "basic-bar2",
        type: "bar",
        height: 350,
        stacked: true,
        stackType: "100%",
      },
      legend: {
        position: "bottom",
      },
      xaxis: {
        categories: dataDepartmentCategories,
        labels: {
          trim: true,
          show: true,
          hideOverlappingLabels: false,
          rotateAlways: true,
          showDuplicates: true,
        },
      },

      colors: ["#BBBCBC", "#041C2C", "#88DBDF", "#00B176", "#FF7E31"],
    },
  };

  useEffect(() => {
    if (isLoading) {
      let promises: any = [];

      promises.push(
        getCurrentStates().then((response: any) => {
          let aux: any = [];
          response.data.forEach((status: any) => {
            aux.push(status[1]);
          });
          setdataStatesChart(aux);
          setdataStates(response.data);
        })
      );
      promises.push(
        getCurrentStatesByDeparment().then((response: any) => {
          let aux: any = [];
          let auxCategories: any = [];
          response.data
            .filter((status: any, i: number) => {
              let aux = false;
              status.stats.forEach((stats: any, i: number) => {
                if (stats[1] !== 0) {
                  aux = true;
                }
              });
              return aux;
            })
            .forEach((status: any) => {
              auxCategories.push(status.name);

              status.stats.forEach((stats: any, i: number) => {
                let find = aux.find((s: any) => s.name === stats[0]);
                if (find) {
                  find.data.push(stats[1]);
                } else {
                  aux[i] = {
                    name: stats[0],
                    data: [stats[1]],
                  };
                }
              });
            });

          setDataDepartmentChart(aux);
          setDataDepartmentCategories(auxCategories);
        })
      );

      //Esta promesa devuelve un 401 -> Error: Unauthorized
      promises.push(
        getCurrentPerformance().then((response: any) => {
          setDataPerformance(response.data);
        })
      );
      Promise.all(promises)
        .then(() => {
          setHaveStatistics(true);
        })
        .catch(() => {
          setHaveStatistics(false);
        });
    }
  }, [isLoading, haveStatistics]);

  useEffect(() => {
    if (
      dataStatesChart &&
      dataStatesChart.length > 0 &&
      dataStates &&
      dataStates.length > 0 &&
      dataDepartmentChart &&
      dataDepartmentChart.length > 0
    ) {
      setIsLoading(false);
    }
  }, [dataStatesChart, dataDepartmentChart, dataStates]);

  if (isLoading) {
    return <AedasLoading></AedasLoading>;
  }

  if (haveStatistics) {
    return (
      <div className="px-4">
        <h1 className="titleTeams">Estadisticas</h1>

        <div className="row d-flex justify-content-xl-between">
          <div className="col-12 col-md-4 col-xl-2  p-4 p-xl-0">
            <BoxStatistics
              value={dataStates[0][1]}
              title="Pendiente"
            ></BoxStatistics>
          </div>
          <div className="col-12 col-md-4 col-xl-2 p-4 p-xl-0">
            <BoxStatistics
              value={dataStates[1][1]}
              title="Validando"
            ></BoxStatistics>
          </div>
          <div className="col-12 col-md-4 col-xl-2  p-4 p-xl-0">
            <BoxStatistics
              value={dataStates[4][1]}
              title="Rechazado"
            ></BoxStatistics>
          </div>
          <div className="col-12 col-md-4 col-xl-2  p-4 p-xl-0">
            <BoxStatistics
              value={dataStates[2][1]}
              title="Esperando R."
            ></BoxStatistics>
          </div>
          <div className="col-12 col-xl-2 col-md-4  p-4 p-xl-0">
            <BoxStatistics
              value={dataStates[3][1]}
              title="Firmado"
            ></BoxStatistics>
          </div>
          <div className="col-12 col-xl-2 col-md-4  p-4 p-xl-0">
            <BoxStatistics
              value={dataPerformance}
              title="Evaluación de Desempeño"
            ></BoxStatistics>
          </div>
        </div>
        <div className="d-flex justify-content-center row p-4">
          <div className="col-12  col-xxl-4 p-4 row widthCustom">
            <div className="d-flex justify-content-center col-xl-6 col-xxl-12">
              <div className="justify-content-center boxChart mb-4">
                <h1 className="chartTitle">Estado de plantillas</h1>
                <Chart
                  options={pieChart.options}
                  series={dataStatesChart}
                  type="pie"
                  width="400"
                ></Chart>
              </div>
            </div>
            <div className="d-flex justify-content-center col-xl-6 col-xxl-12">
              <div className="boxChart ">
                <h1 className="chartTitle">Estado de plantillas</h1>
                <Chart
                  options={pieChart.options}
                  series={dataStatesChart}
                  type="pie"
                  width="400"
                ></Chart>
              </div>
            </div>
          </div>
          <div className="col-12 col-xl-12 col-xxl-8 p-4 d-flex justify-content-center align-items-center">
            <div
              style={{ width: "889px", height: "577px" }}
              className="boxChart"
            >
              <h1 className="chartTitle">Estado por departamento</h1>
              <div className="d-flex justify-content-center">
                <Chart
                  options={barChart.options}
                  series={dataDepartmentChart}
                  type="bar"
                  width="800"
                ></Chart>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 col-xl-4 p-4"></div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="px-4">
        <h1 className="titleTeams">Estadisticas</h1>
        <div className="d-flex justify-content-center pt-4">
          <h2 style={{ color: "var(--aedas-red)" }}>
            No hay estadisticas para este año
          </h2>
        </div>
      </div>
    );
  }
}

export default Statistics;
