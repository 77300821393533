import OrganizationChart from "@dabeng/react-orgchart";
import React, { useEffect, useState } from "react";
import { getOrganigrama } from "../../../../Service/EmployeeService/EmployeeConfig";
import AedasLoading from "../../../Shared/AedasLoading/AedasLoading";
import MyNode from "./customNode";


function Organigrama(props) {
  const [data, setData] = useState();
  
  const { company } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);


  useEffect(()=>{

    async function giveMeData() {
      if (isLoading) {
        const response = await getOrganigrama(company?.id).catch((error) => {
          setError(error);
        });
  
        if (response && typeof response === "object") {
          setData(response.data);
          setIsLoading(false);
          setError(null);
        } else {
          setData(null);
          setIsLoading(false);
        }
      }
    }

    giveMeData();

  },[company?.id, isLoading])

  /*
  useEffect(async () => {
    if (isLoading) {
      const response = await getOrganigrama(company?.id).catch((error) => {
        console.log(error);
        setError(error);
      });

      if (response && typeof response === "object") {
        setData(response.data);
        setIsLoading(false);
        setError(null);
      } else {
        setData(null);
        setIsLoading(false);
      }
    }
  }, [company?.id, isLoading]);
  */

  useEffect(() => {
    setIsLoading(true);
  }, [company]);

  if (isLoading) {
    return <AedasLoading></AedasLoading>;
  }
  return (
    <>
      {error ? (
        <div className="d-flex justify-content-center">
          <h1 style={{ color: "var(--aedas-red)" }}>
            {"No hay datos para esta empresa"}
          </h1>
        </div>
      ) : data ? (
        <OrganizationChart
          datasource={data}
          chartClass="myChart"
          NodeTemplate={MyNode}
        />
      ) : (
        <></>
      )}
    </>
  );
}

export default Organigrama;
