import axios from "axios";
import { getDefaultConfigHeader } from "../../Utils/headerDefaultConfigPetition";

export const getCurrentStates = () => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/statistics/current",
    getDefaultConfigHeader()
  );
};

export const getCurrentStatesByDeparment = () => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/statistics/department",
    getDefaultConfigHeader()
  );
};

export const getCurrentPerformance = () => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/statistics/evaluacion/",
    getDefaultConfigHeader()
  );
};
