/* import _ from "lodash"; */
import Checkbox from "@mui/material/Checkbox";
import {
  GridColDef,
  GridRenderEditCellParams,
  useGridApiContext,
} from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  changeActivation,
  getAllEmployee,
} from "../../../../Service/EmployeeService/EmployeeConfig";
import AedasLoading from "../../../Shared/AedasLoading/AedasLoading";
import DataGridEditabled from "../../../Shared/DataGridEditabled/DataGridEditabled";

function EmployeesDesactive(props: any) {
  const [Employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setIsLoading(true);
  }, [props.date]);

  useEffect(() => {
    if (isLoading) {
      let promises = [];
      promises.push(
        getAllEmployee().then((response: any) => {
          setEmployees(response.data);
        })
      );
      Promise.all(promises).then(() => setIsLoading(false));
    }
  }, [isLoading]);

  useEffect(() => {
    setIsLoading(true);
  }, [isSaving]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 9,
      hide: true,
      editable: false,
    },

    {
      field: "display_name",
      headerName: "Empleado",
      type: "text",
      width: 200,
      editable: false,
      flex: 1,
    },

    {
      field: "active",
      headerName: "Activo",
      type: "text",
      renderCell: (params: GridRenderEditCellParams) => {
        return <>{params.value === true ? "Activado" : "Desactivado"}</>;
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return <CustomEditComponent {...params}></CustomEditComponent>;
      },
      width: 200,
      editable: true,
      flex: 1,
    },
  ];

  const handleSave = (data: any) => {
    let promises: any = [];
    data.edited.forEach((employee: any) => {
      const emp = {
        id: employee.id,
        id_company: employee.id_company,
        id_rol: employee.id_rol,
        id_department: employee.id_department,
        name: employee.name,
        surname: employee.surname,
        email: employee.name,
        telephone: employee.telephone,
        id_azure: employee.id_azure,
        display_name: employee.display_name,
        url_img: employee.url_img,
        id_position: employee.id_position,
        active: employee.active,
      };
      promises.push(changeActivation(employee.active, employee));
    });

    setIsSaving(true);

    Promise.all(promises)
      .then(() => toast.success("Guardado"))
      .catch((error) => toast.error("Ha habido un problema"));
  };
  return (
    <>
      {isLoading ? (
        <AedasLoading></AedasLoading>
      ) : (
        <DataGridEditabled
          onlyEditFunct={true}
          onlyEdit={true}
          handleSave={handleSave}
          rows={Employees}
          columns={columns}
          title=""
        ></DataGridEditabled>
      )}
    </>
  );
}

export default EmployeesDesactive;

function CustomEditComponent(props: any) {
  const { id, value, field } = props;
  const [active, setActive] = useState(value);
  const apiRef = useGridApiContext();

  const handleChange = (event: any, newValue: any | null) => {
    setActive(!active);
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return <Checkbox checked={active} onChange={handleChange} />;
}
