export const userLogin = (user: any, token: string, position: string) => {
  return {
    type: "@user/login",
    payload: { user: user, token: token, position: position },
  };
};

export const userLogout = () => {
  return {
    type: "@user/logout",
  };
};
