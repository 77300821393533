import LoginForm from "./LoginForm";
import "./Login.css";
import { Redirect } from "react-router-dom";

function Login(props: any) {
  if (props.isLogin) {
    return <Redirect to="/"></Redirect>;
  }
  return (
    <div>
      <div className="backgraundLogin">
        <div className="boxLogin row">
          <div
            style={{ height: "70px" }}
            className="row d-flex justify-content-center"
          >
            <img
              alt="Logotipo de AEDAS"
              className="col-12 p-4"
              src="/Assets/img/AF_AEDAS_POSITIVO_RGB.svg"
              style={{ width: "150px" }}
            ></img>
            <div className=" col-12 d-flex">
              <h1 className="appNameLogin">
                Aplicación para la gestión de los objetivos anuales
              </h1>
            </div>
            <div>
              <p className="descriptionLogin">
                Ahora podrás revisar y firmar tus objetivos anuales en una
                aplicación, para acceder solo tienes que pulsar el botón Entrar
                y reconoceremos automáticamente tu perfil
              </p>
            </div>
            <div>
              <div className="buttonLogin">
                <LoginForm setisLogin={props.setisLogin}></LoginForm>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
