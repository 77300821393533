import * as React from "react";
import { useSelector } from "react-redux";
import { Tab, TabList, Tabs, TabPanel } from "react-tabs";
import { getCurrentPeriod } from "../../../Utils/aedasDateUtil";
import "./CurrentYear.css";
import Objetives from "./Objetives/Objetives";
import Roles from "./Roles/Roles";
function CurrentYear() {
  const year = useSelector((state: any) => state.yearReducer.year);
  return (
    <div className="px-4 h-100">
      <h1 className="titleCurrentYear">
        Objetivos de {getCurrentPeriod(year)}
      </h1>
      <Tabs>
        <TabList>
          <Tab>Objetivos</Tab>
          <Tab>Roles</Tab>
        </TabList>

        <TabPanel>
          <Objetives></Objetives>
        </TabPanel>
        <TabPanel>
          <Roles></Roles>
        </TabPanel>
      </Tabs>
    </div>
  );
}

export default CurrentYear;
