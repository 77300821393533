import {
  DataGrid,
  GridColDef,
  GridRowParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { getUserNotificationsTemplate } from "../../../Service/TemplateService/TemplateService";
import AedasLoading from "../AedasLoading/AedasLoading";

export default function ShowNotifications(props: any) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const history = useHistory();
  useEffect(() => {
    getUserNotificationsTemplate(
      props.selectedRow ? props.selectedRow.id_employee : props.id_employee
    )
      .then((response: any) => {
        let aux = response.data.map((per: any, i: number) => {
          return {
            id: i,
            employee_display_name: per.employee_display_name,
            id_employee: per.id_employee,
            manager_display_name: per.manager_display_name,
            id_template: per.id_template,
            status: per.status,
            notifications: per.notifications,
          };
        });
        setData(aux);
        setIsLoading(false);
      })
      .catch(() => {
        toast.error("Ha habido un problema");
        setIsError(true);
        setIsLoading(false);
      });
  }, []);

  const handleClickRow = (params: GridRowParams) => {
    switch (params.row.status) {
      case 2:
        history.push("/equipo?tab=1");
        break;
      case 3:
        history.push("/equipo?tab=2");
        break;

      default:
        history.push("/equipo");
        break;
    }
  };
  const columns: GridColDef[] = [
    { field: "id_employee", headerName: "ID_employee", width: 90, hide: true },
    { field: "id", headerName: "ID", width: 90, hide: true },
    { field: "id_template", headerName: "Template", width: 90, hide: true },
    {
      field: "employee_display_name",
      headerName: "Empleado",
      width: 300,
      flex: 1,
    },
    {
      field: "manager_display_name",
      headerName: "Manager",
      width: 150,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Estado",
      width: 150,
      flex: 1,
      renderCell: (params: GridValueGetterParams) => {
        const states = [
          { id: 1, name: "Pendiente", color: "#BBBCBC" },
          { id: 2, name: "Validando", color: "#041C2C" },
          { id: 3, name: "Pendiente de R.", color: "#88DBDF" },
          { id: 4, name: "Firmado", color: "#00B176" },
          { id: 5, name: "Rechazado", color: "#FF7E31" },
          { id: 6, name: "Rechazado", color: "#FF7E31" },
        ];
        const StateName: any = states.find((r) => r.id === params.value);
        return (
          <>
            <span
              style={{ backgroundColor: StateName.color }}
              className="chipTeamStatus"
            >
              {StateName ? StateName.name : ""}
            </span>
          </>
        );
      },
    },
  ];

  if (isLoading) {
    return <AedasLoading></AedasLoading>;
  }
  return (
    <div style={{ height: "500px" }}>
      {data.length === 0 || isError ? (
        <h1 style={{ textAlign: "center" }}>No tienes acciones pendientes</h1>
      ) : (
        <DataGrid
          columns={columns}
          rows={data}
          onRowClick={handleClickRow}
          disableSelectionOnClick
        ></DataGrid>
      )}
    </div>
  );
}
