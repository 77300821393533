import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getAllPositions } from "../../../../Service/DepartmentService/PositionService";
import {
  addObjetivesPersonal,
  getObjectivesByYear,
  removeObjetives,
} from "../../../../Service/ObjectiveService/ObjectiveService";
import { getSteeringCommittee } from "../../../../Service/SteeringCommitteeService/SteeringCommitteeService";
import { getTeamSelf } from "../../../../Service/TeamService/TeamService";
import { sendToValidate } from "../../../../Service/TemplateService/TemplateService";
import { isInteger } from "../../../../Utils/integerUtils";
import AedasLoading from "../../../Shared/AedasLoading/AedasLoading";
import CustomModal from "../../../Shared/ModalCustom/CustomModal";
import TeamPersonBox from "../../../Shared/TeamPersonBox/TeamPersonBox";
import ManagerObjectivesModal from "./ManagerObjectivesModal/ManagerObjectivesModal";
import "./SteeringCommittee.css";

function SteeringCommittee(props: any) {
  const user = useSelector((state: any) => state.userReducer.user);
  const [team, setTeam] = useState([]);
  const [me, setMe] = useState<any>(null);
  const [positions, setPositions] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [openModal, setOpenModal] = useState<any>(false);
  const [modalData, setModalData] = useState<any>(null);
  const [IsLoadingButtonSendValidate, setIsLoadingButtonSendValidate] =
    useState(false);
  const year = useSelector((state: any) => state.yearReducer.year);

  const handelOpenModal = (id: any) => {
    setModalData(team.find((t: any) => t.id_employee === id));
  };
  useEffect(() => {
    if (modalData) {
      setOpenModal(true);
    }
  }, [modalData]);
  useEffect(() => {
    if (!openModal) {
      setModalData(null);
      setIsLoading(true);
    }
  }, [openModal]);

  useEffect(() => {
    if (isLoading) {
      let promises = [];
      promises.push(
        getSteeringCommittee().then((response) => {
          setTeam(response.data);
        })
      );
      promises.push(
        getTeamSelf(user.employee.id).then((response) => {
          setMe(response.data);
        })
      );
      promises.push(
        getAllPositions().then((response: any) => {
          setPositions(response.data);
        })
      );
      Promise.all(promises).catch((error) => {
        if (error.response.status !== 404) {
          toast.error("Ha habido un problema");
        }
      });
    }
  }, [isLoading, user.employee.id]);

  useEffect(() => {
    if (team && positions && me && positions.length > 0) {
      setIsLoading(false);
    }
  }, [team, me, positions]);
  const [rowsAux, setrowsAux] = useState<any>(null);
  const handleAutoSave = (rows: any) => {
    setrowsAux(rows);
  };
  const handleSave = (rows: any) => {
    let promisesArray: any = [];

    rows.news.forEach((n: any) => {
      let tuple: any = [];

      for (var property in n) {
        if (isInteger(property)) {
          tuple.push({
            number: parseInt(property),
            value: n[property],
          });
        }
      }

      const config = {
        objective: {
          id_employee: modalData.id_employee,
          valoration: n.valoration,
          objective_name: n.objective,
        },
        percent_values: [...tuple],
      };
      promisesArray.push(addObjetivesPersonal(config));
    });

    rows.edited.forEach((n: any) => {
      let tuple: any = [];
      for (var property in n) {
        if (isInteger(property)) {
          tuple.push({
            number: parseInt(property),
            value: n[property],
          });
        }
      }

      const config = {
        objective: {
          id_employee: modalData.id_employee,
          valoration: n.valoration,
          objective_id: n.id_objective,
          objective_name: n.objective,
        },
        percent_values: tuple.filter((t: any) => t[1] !== null),
      };
      promisesArray.push(addObjetivesPersonal(config));
    });

    rows.removed.forEach((n: any) => {
      promisesArray.push(removeObjetives(n.id_objective));
    });

    Promise.all(promisesArray)
      .then(() => {
        toast.success("Guardado");
      })
      .catch(() => toast.error("Ha habido un error"));
  };
  const handleSaveAndSendTovalidate = (rows: any) => {
    setIsLoadingButtonSendValidate(true);
    let promisesArray: any = [];

    rows?.news.forEach((n: any) => {
      let tuple: any = [];

      for (var property in n) {
        if (isInteger(property)) {
          tuple.push({
            number: parseInt(property),
            value: n[property],
          });
        }
      }

      const config = {
        objective: {
          id_employee: modalData.id_employee,
          valoration: n.valoration,
          objective_name: n.objective,
        },
        percent_values: [...tuple],
      };
      promisesArray.push(addObjetivesPersonal(config));
    });

    rows?.edited.forEach((n: any) => {
      let tuple: any = [];
      for (var property in n) {
        if (isInteger(property)) {
          tuple.push({
            number: parseInt(property),
            value: n[property],
          });
        }
      }

      const config = {
        objective: {
          id_employee: modalData.id_employee,
          valoration: n.valoration,
          objective_id: n.id_objective,
          objective_name: n.objective,
        },
        percent_values: tuple.filter((t: any) => t[1] !== null),
      };
      promisesArray.push(addObjetivesPersonal(config));
    });

    rows?.removed.forEach((n: any) => {
      promisesArray.push(removeObjetives(n.id_objective));
    });

    Promise.all(promisesArray).then(() => {
      hadleValidate();
    });
  };

  const hadleValidate = () => {
    getObjectivesByYear(modalData.id_employee, year)
      .then((response) => {
        let personal = response.data.find(
          (obj: any) => obj.obj_type === "PERSONAL"
        );
        if (personal.objectives.length <= 0 && personal.percentage !== 0) {
          toast.error("El empleado no tiene objetivos personales");
          setIsLoadingButtonSendValidate(false);
        } else {
          let count = 0;
          personal.objectives.forEach((obj: any) => {
            count += obj.valoration;
          });
          if (count === personal.percentage) {
            sendToValidate(user.employee.id, modalData?.id_employee, true)
              .then((responseValidate) => {
                toast.success("Mandado a validar");
                setIsLoadingButtonSendValidate(false);
                setIsLoading(true);
                setOpenModal(false);
              })
              .catch((error) => {
                setIsLoadingButtonSendValidate(false);
                toast.error("Ha habido un problema");
              });
          } else {
            setIsLoadingButtonSendValidate(false);
            toast.error(
              "El porcentaje asignable debe ser de:" + personal.percentage + "%"
            );
          }
        }
      })
      .catch((error) => {
        setIsLoadingButtonSendValidate(false);
        toast.error("Ha habido un error");
      });
  };
  useEffect(() => {
    if (!openModal) {
      setIsLoading(true);
    }
  }, [openModal]);
  if (isLoading) {
    return <AedasLoading></AedasLoading>;
  }
  return (
    <>
      <CustomModal
        size="xl"
        show={openModal}
        onChange={() => {
          if (!openModal === false && rowsAux) {
            handleSave(rowsAux);
            setOpenModal(!openModal);
          } else {
            setOpenModal(!openModal);
          }
        }}
        title="Administrar objetivos"
        body={
          <ManagerObjectivesModal
            setrowsAux={setrowsAux}
            handleAutoSave={handleAutoSave}
            handleSave={handleSave}
            rowsAux={rowsAux}
            IsLoadingButtonSendValidate={IsLoadingButtonSendValidate}
            modalData={modalData}
            handleSaveAndSendTovalidate={handleSaveAndSendTovalidate}
            setOpenModal={setOpenModal}
          ></ManagerObjectivesModal>
        }
      ></CustomModal>

      <div className="col-12 d-flex justify-content-center row whiteBox_team pb-4">
        {team.map((employee: any) => {
          return (
            <TeamPersonBox
              key={employee.id_employee}
              id={employee.id_employee}
              handelOpenModal={handelOpenModal}
              state={employee.id_status}
              title={employee.display_name}
              subtitle={
                positions.find((pos: any) => pos.id === employee.id_position)
                  .name
              }
              img={employee.url_img}
              admin={true}
            ></TeamPersonBox>
          );
        })}
      </div>
    </>
  );
}

export default SteeringCommittee;
