import { Autocomplete } from "@mui/lab";
import { TextField } from "@mui/material";
import {
  GridColDef,
  GridRenderEditCellParams,
  useGridApiContext,
} from "@mui/x-data-grid";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getAllCompany } from "../../../../Service/CompanyService/CompanyService";
import { getAllCostsCenter } from "../../../../Service/CostCenter/CostCenter";
import { getAllDelegations } from "../../../../Service/DelegationService/DelegationService";
import { getAllPositions } from "../../../../Service/DepartmentService/PositionService";
import {
  addAssignment,
  assignmentPositionEmployees,
  getAllEmployeeTable,
  getAssignmentEmployees,
  getEmployeeByCompany,
  removeAssignment,
  updateEmployee,
} from "../../../../Service/EmployeeService/EmployeeConfig";
import AedasLoading from "../../../Shared/AedasLoading/AedasLoading";
import DataGridEditabled from "../../../Shared/DataGridEditabled/DataGridEditabled";

function Employees(props: any) {
  const [Employees, setEmployees] = useState([]);
  const [EmployeesCopy, setEmployeesCopy] = useState([]);
  const [assignmentEmployees, setAssignmentEmployeesArray] = useState([]);
  const [costsCenter, setCostsCenter] = useState([]);
  const [position, setPositions] = useState([]);
  const [company, setCompany] = useState([]);
  const [delegation, setDelegation] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isLoading) {
      let promises = [];
      promises.push(
        getAllEmployeeTable().then((response: any) => {
          setEmployees(response.data);
          setEmployeesCopy(JSON.parse(JSON.stringify(response.data)));
        })
      );
      promises.push(
        getAssignmentEmployees().then((response: any) => {
          response.data.unshift({ id: null, display_name: "Sin asignación" });
          setAssignmentEmployeesArray(response.data);
        })
      );
      promises.push(
        getAllCompany().then((response: any) => {
          setCompany(response.data);
        })
      );
      promises.push(
        getAllPositions().then((response: any) => {
          setPositions(response.data);
        })
      );
      promises.push(
        getAllDelegations()
          .then((response: any) => {
            setDelegation(response.data);
          })
          .catch((error) => {})
      );
      promises.push(
        getAllCostsCenter().then((response: any) => {
          setCostsCenter(response.data);
        })
      );
      Promise.all(promises)
        .then(() => setIsLoading(false))
        .catch((error) => {
          if (error.status.code === 404) {
            setIsLoading(false);
          } else {
            toast.error("Ha habido un error");
          }
        });
    }
  }, [isLoading]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 9,
      hide: true,
      editable: false,
    },

    {
      field: "display_name",
      headerName: "Empleado",
      type: "text",
      width: 200,
      editable: false,
      flex: 1,
    },
    {
      field: "id_company",
      headerName: "Empresa",
      type: "text",
      renderCell: (params: GridRenderEditCellParams) => {
        const comp: any = company.find(
          (c: any) => c.id === params.row.id_company
        );
        return <div>{comp ? comp.name : ""}</div>;
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return <CustomEditComponentCompany {...params} company={company} />;
      },
      width: 200,
      editable: true,
      flex: 1,
    },
    {
      field: "id_manager",
      headerName: "Asignación",
      width: 200,
      editable: true,
      renderCell: (params: GridRenderEditCellParams) => {
        if (!params.row.id_manager) {
          return <div>Sin asignación</div>;
        }
        const emp: any = Employees.find(
          (e: any) => e.id === params.row.id_manager
        );
        return <div>{emp ? emp.display_name : ""}</div>;
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <CustomEditComponent
            {...params}
            assignmentEmployees={assignmentEmployees}
          />
        );
      },

      flex: 1,
    },
    {
      field: "id_position",
      headerName: "Rol",
      width: 200,
      editable: true,
      renderCell: (params: GridRenderEditCellParams) => {
        if (!params.row.id_position) {
          return <div>Sin asignación</div>;
        }
        const rol: any = position.find(
          (p: any) => p.id === params.row.id_position
        );
        return <div>{rol ? rol.name : ""}</div>;
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return <CustomEditComponentPosition {...params} position={position} />;
      },

      flex: 1,
    },
    {
      field: "delegacion_provincial",
      headerName: "Delegación",
      width: 200,
      editable: true,
      renderCell: (params: GridRenderEditCellParams) => {
        if (!params.row.delegacion_provincial) {
          return <div>Sin delegación</div>;
        }
        const delegacion: any = delegation.find(
          (p: any) => p.id === params.row.delegacion_provincial
        );
        return <div>{delegacion ? delegacion.name : ""}</div>;
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <CustomEditComponentDelegation {...params} delegation={delegation} />
        );
      },

      flex: 1,
    },
    {
      field: "id_tag",
      headerName: "Centro de coste",
      editable: true,
      width: 200,
      renderCell: (params: GridRenderEditCellParams) => {
        const cost: any = costsCenter.find(
          (cc: any) => cc.id === params.row.id_tag
        );

        return <div>{cost ? cost.name : "Sin centro de coste"}</div>;
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <CustomEditComponentCostsCenter
            {...params}
            costsCenter={costsCenter}
          />
        );
      },
      flex: 1,
    },
    {
      field: "email",
      type: "text",
      headerName: "Email",
      editable: false,
      width: 200,
      flex: 1,
    },
    {
      field: "telephone",
      type: "text",
      headerName: "Teléfono",
      editable: false,
      width: 200,
      flex: 1,
    },
  ];

  const handleSave = (data: any) => {
    let arrayPromises: any = [];

    data.edited.forEach((emp: any) => {
      let aux: any = EmployeesCopy.find((empl: any) => empl.id === emp.id);

      if (aux && aux.id_company !== emp.id_company) {
        arrayPromises.push(
          updateEmployee(
            //props.date,
            emp.id,
            emp.id_company,
            emp.delegacion_provincial,
            emp.id_tag
          )
        );
      }

      if (aux && emp.id_manager !== aux.id_manager && emp.id_manager !== null) {
        arrayPromises.push(addAssignment(emp.id_manager, emp.id));
      }

      if (emp.id_manager === null && aux.id_manager !== null) {
        arrayPromises.push(removeAssignment(aux.id));
      }

      if (aux && emp.id_position !== aux.id_position) {
        arrayPromises.push(
          assignmentPositionEmployees(
            moment(props.date).format("YYYY"),
            aux.id,
            emp.id_position,
            emp.delegacion_provincial
          )
        );
      }

      if (aux && aux.id_tag !== emp.id_tag) {
        arrayPromises.push(
          updateEmployee(
            emp.id,
            emp.id_company,
            emp.delegacion_provincial,
            emp.id_tag
          )
        );
      }

      if (
        aux &&
        emp.delegacion_provincial !== aux.delegacion_provincial &&
        emp.delegacion_provincial !== 0
      ) {
        arrayPromises.push(
          assignmentPositionEmployees(
            moment(props.date).format("YYYY"),
            emp.id,
            emp.id_position,
            emp.delegacion_provincial
          )
        );
      } else if (
        aux &&
        emp.delegacion_provincial !== aux.delegacion_provincial &&
        emp.delegacion_provincial === 0
      ) {
        arrayPromises.push(
          updateEmployee(
            emp.id,
            emp.id_company,
            emp.delegacion_provincial,
            emp.id_tag
          )
        );
      }
    });

    Promise.all(arrayPromises)
      .then(() => {
        setIsLoading(true);
        toast.success("Datos actualizados");
      })
      .catch(() =>
        toast.error(
          "Ha habido un problema al guardar datos, prueba en unos minutos"
        )
      );
  };

  return (
    <>
      {isLoading ? (
        <AedasLoading></AedasLoading>
      ) : (
        <DataGridEditabled
          onlyEditFunct={true}
          onlyEdit={true}
          handleSave={handleSave}
          rows={Employees}
          columns={columns}
          title=""
        ></DataGridEditabled>
      )}
    </>
  );
}

export default Employees;

function CustomEditComponent(props: any) {
  const { id, field } = props;
  const apiRef = useGridApiContext();
  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    getEmployeeByCompany(props.row.id_company, props.row.id).then(
      (response: any) => {
        let dataAux = response.data.concat({
          id: null,
          display_name: "Sin asignación",
        });

        setData(dataAux);
        setIsLoading(false);
      }
    );
  }, [props.row.id, props.row.id_company]);

  const handleChange = (event: any, newValue: any | null) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue.id });
  };

  return (
    <Autocomplete
      onChange={handleChange}
      disablePortal
      loading={isLoading}
      id="combo-box-demo"
      options={data}
      getOptionLabel={(option: any) => option.display_name}
      sx={{ flex: 1 }}
      renderInput={(params: any) => <TextField {...params} label="" />}
    />
  );
}

function CustomEditComponentCompany(props: any) {
  const { id, field } = props;
  const apiRef = useGridApiContext();

  const handleChange = (event: any, newValue: any | null) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue.id });
  };

  return (
    <Autocomplete
      onChange={handleChange}
      disablePortal
      id="combo-box-demo"
      options={props.company}
      getOptionLabel={(option: any) => option.name}
      sx={{ flex: 1 }}
      renderInput={(params: any) => <TextField {...params} label="" />}
    />
  );
}

function CustomEditComponentDelegation(props: any) {
  const { id, field } = props;
  const apiRef = useGridApiContext();

  const handleChange = (event: any, newValue: any | null) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue.id });
  };

  return (
    <Autocomplete
      onChange={handleChange}
      disablePortal
      id="combo-box-demo"
      options={props.delegation.concat({ id: 0, name: "Sin delegación" })}
      getOptionLabel={(option: any) => option.name}
      sx={{ flex: 1 }}
      renderInput={(params: any) => <TextField {...params} label="" />}
    />
  );
}

function CustomEditComponentPosition(props: any) {
  const { id, field } = props;
  const apiRef = useGridApiContext();

  const handleChange = (event: any, newValue: any | null) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue.id });
  };

  return (
    <Autocomplete
      onChange={handleChange}
      disablePortal
      id="combo-box-demo"
      options={props.position.filter(
        (pos: any) => pos.id_company === props.row.id_company
      )}
      getOptionLabel={(option: any) => option.name}
      sx={{ flex: 1 }}
      renderInput={(params: any) => <TextField {...params} label="" />}
    />
  );
}

function CustomEditComponentCostsCenter(props: any) {
  const { id, field } = props;
  const apiRef = useGridApiContext();

  const handleChange = (event: any, newValue: any | null) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue.id });
  };

  return (
    <Autocomplete
      onChange={handleChange}
      disablePortal
      id="combo-box-cost-center"
      options={props.costsCenter}
      getOptionLabel={(option: any) => option.name}
      sx={{ flex: 1 }}
      renderInput={(params: any) => <TextField {...params} label="" />}
    />
  );
}
