import React, { useState, useEffect } from "react";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import "./SignObjetives.css";
import CustomModal from "../../../Shared/ModalCustom/CustomModal";
import ShowObjetivesContent from "../../../Shared/ShowObjetives/ShowObjetivesContent";
import RejectModalContent from "../../../Shared/RejectModalContent/RejectModalContent";
import SignObjetivesModalContent from "./SignObjetivesModalContent/SignObjetivesModalContent";
import AedasLoading from "../../../Shared/AedasLoading/AedasLoading";
import { useSelector } from "react-redux";
import { getSingsByManager } from "../../../../Service/TemplateService/TemplateService";
import { toast } from "react-toastify";

function SignObjetives(props: any) {
  const [openModalShow, setOpenModalShow] = useState<boolean>(false);
  const [openModalSign, setOpenModalSign] = useState<boolean>(false);
  const [openModalReject, setOpenModalReject] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const user = useSelector((state: any) => state.userReducer.user);

  const [selectedRow, setSelectedRow] = useState<any>(null);

  useEffect(() => {
    if (!openModalReject) {
      setIsLoading(true);
    }
    if (openModalSign) {
      setIsLoading(true);
    }
  }, [openModalSign, openModalReject]);

  useEffect(() => {
    if (isLoading) {
      getSingsByManager(user.employee.id)
        .then((response: any) => {
          let aux = response.data.map((per: any, i: number) => {
            return {
              id: i,
              employee_display_name: per.employee_display_name,
              id_employee: per.id_employee,
              manager_display_name: per.manager_display_name,
              id_template: per.id_template,
              status: per.status,
              url_img: per.url_img,
            };
          });
          setData(aux);
          setIsLoading(false);
        })
        .catch((error) => {
          if (error.request.status === 404) {
          } else {
            toast.error("Ha habido un problema");
          }
          setData([]);
          setIsLoading(false);
        });
    }
  }, [isLoading, user.employee.id]);

  const handelReject = (params: any) => {
    setSelectedRow(
      data.find((row: any) => row.id_template === params.row.id_template)
    );
    setOpenModalReject(true);
  };

  const handelShowObjetives = (params: any) => {
    setSelectedRow(
      data.find((row: any) => row.id_template === params.row.id_template)
    );
    setOpenModalShow(true);
  };

  const handleSign = (params: any) => {
    setSelectedRow(
      data.find((row: any) => row.id_template === params.row.id_template)
    );
    setOpenModalSign(true);
  };
  const columns: GridColDef[] = [
    { field: "id_template", headerName: "ID", width: 90, hide: true },
    { field: "id", headerName: "ID", width: 90, hide: true },
    {
      field: "employee_display_name",
      headerName: "Empleado",
      width: 300,
      flex: 1,
    },
    {
      field: "manager_display_name",
      headerName: "Manager",
      width: 150,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Estado",
      width: 150,
      flex: 1,
      renderCell: (params: GridValueGetterParams) => {
        const states = [
          { id: 1, name: "Pendiente", color: "#BBBCBC" },
          { id: 2, name: "Validando", color: "#041C2C" },
          { id: 3, name: "Pendiente de R.", color: "#88DBDF" },
          { id: 4, name: "Firmado", color: "#00B176" },
          { id: 5, name: "Rechazado", color: "#FF7E31" },
          { id: 6, name: "Rechazado", color: "#FF7E31" },
        ];
        const StateName: any = states.find((r) => r.id === params.value);
        return (
          <>
            <span
              style={{ backgroundColor: StateName.color }}
              className="chipTeamStatus"
            >
              {StateName ? StateName.name : ""}
            </span>
          </>
        );
      },
    },
    {
      field: "actions",
      headerName: "Acciones",
      width: 400,
      flex: 1,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <>
            <Button
              onClick={() => handelShowObjetives(params)}
              color="primary"
              className="showObjetives_Button"
            >
              Ver objetivos
            </Button>
            <Button
              color="primary"
              className="validateObjetives_Button"
              onClick={() => handleSign(params)}
            >
              Firmar
            </Button>
            <Button
              onClick={() => handelReject(params)}
              color="primary"
              className=" rejectObjetives_Button "
            >
              Rechazar
            </Button>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (!openModalReject && !openModalSign) {
      setIsLoading(true);
    }
  }, [openModalReject, openModalSign]);
  if (isLoading) {
    return <AedasLoading></AedasLoading>;
  }
  return (
    <>
      <CustomModal
        size="xl"
        show={openModalShow}
        onChange={() => {
          setOpenModalShow(!openModalShow);
        }}
        title="Ver Objetivos"
        body={
          <ShowObjetivesContent
            selectedRow={selectedRow}
            setOpen={setOpenModalReject}
          ></ShowObjetivesContent>
        }
      ></CustomModal>

      <CustomModal
        size="lg"
        show={openModalReject}
        onChange={() => {
          setOpenModalReject(!openModalReject);
        }}
        title="Confirmar Rechazo"
        body={
          <RejectModalContent
            selectedRow={selectedRow}
            setOpen={setOpenModalReject}
          ></RejectModalContent>
        }
      ></CustomModal>
      <CustomModal
        size="lg"
        show={openModalSign}
        onChange={() => {
          setOpenModalSign(!openModalSign);
        }}
        title="Firmar"
        body={
          <SignObjetivesModalContent
            selectedRow={selectedRow}
            setOpen={setOpenModalSign}
          ></SignObjetivesModalContent>
        }
      ></CustomModal>
      <div className="signObjetives_table_container">
        <DataGrid
          rows={data}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
        />
      </div>
    </>
  );
}

export default SignObjetives;
