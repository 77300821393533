export function isInteger(str: any) {
  if (typeof str !== "string") {
    return false;
  }

  const num = Number(str);

  if (Number.isInteger(num)) {
    return true;
  }

  return false;
}

export const sortObj = (a: any, b: any) => {
  if (a.id === 2 || a.id_objective === 2) {
    return -1;
  } else if (b.id === 2 || b.id_objective === 2) {
    return 1;
  } else if (a.id === 3 || a.id_objective === 3) {
    return -1;
  } else if (b.id === 3 || b.id_objective === 3) {
    return 1;
  } else if (a.id === 1 || a.id_objective === 1) {
    return -1;
  } else if (b.id === 1 || b.id_objective === 1) {
    return 1;
  } else if (a.id === 4 || a.id_objective === 4) {
    return -1;
  } else if (b.id === 4 || b.id_objective === 4) {
    return 1;
  } else if (a.id > b.id) {
    return 1;
  } else if (a.id < b.id) {
    return -1;
  }
  return 0;
};

export const sortObjPersonal = (a: any, b: any) => {
  if (a.valoration < b.valoration) {
    return 1;
  } else if (a.valoration > b.valoration) {
    return -1;
  } else if (a.valoration === b.valoration) {
    if (a.objective > b.objective) {
      return 1;
    } else if (a.objective < b.objective) {
      return -1;
    }
  }
  return 0;
};
