import * as React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import Team from "./team/SteeringCommittee";
import "./SteeringCommitteeMain.css";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import SteeringCommittee from "./team/SteeringCommittee";
function SteeringCommitteeMain(props: any) {
  // const isManager = useSelector((state: any) => state.teamReducer.isManager);
  // if (!isManager) {
  //   return <Redirect to="/"></Redirect>;
  // }
  return (
    <div className="px-4">
      <h1 className="titleTeams">Comite de dirección</h1>
      <Tabs>
        <TabList>
          <Tab>Equipo</Tab>
        </TabList>

        <TabPanel>
          <div className="Team">
            <SteeringCommittee></SteeringCommittee>
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
}

export default SteeringCommitteeMain;
