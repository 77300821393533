import { Button } from "@mui/material";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { publishNextYear } from "../../../../Service/PublishService/PublishService";
import CustomModal from "../../../Shared/ModalCustom/CustomModal";
import "./Publish.css";

function Publish(props: any) {
  const [openModalShow, setOpenModalShow] = useState(false);

  const handlePublish = () => {
    toast.info("Se esta empezando a publicar el nuevo año");

    publishNextYear()
      .then(() => toast.success("Se ha publicado un nuevo año"))
      .catch((error) => {
        if (error.request.code === 404) {
          toast.error(
            "No se ha podido publicar, faltan configuraciones necesarias para la publicación"
          );
        } else {
          toast.error("Ha habido un problema");
        }
      });

    setOpenModalShow(!openModalShow);
  };

  return (
    <>
      <CustomModal
        size='lg'
        show={openModalShow}
        onChange={() => {
          setOpenModalShow(!openModalShow);
        }}
        title='Confirmar'
        body={<PublishModal handlePublish={handlePublish}></PublishModal>}
      ></CustomModal>
      <div className='d-flex justify-content-center w-100'>
        <div className='boxPublish'>
          <h1>Publicar objetivos</h1>
          <p>
            Al dar al siguiente botón, los objetivos definidos, se extienden
            hasta los Managers y el año actual cambiará
          </p>
          <Button
            onClick={() => setOpenModalShow(true)}
            color='primary'
            className='publish_button'
          >
            Publicar
          </Button>
        </div>
      </div>
    </>
  );
}

export default Publish;

function PublishModal(props: any) {
  return (
    <div className='d-flex justify-content-center w-100'>
      <div className='boxPublish'>
        <p>
          Confirmo la publicación del nuevo año, este cambio es irreversible.
        </p>
        <Button
          onClick={() => props.handlePublish()}
          color='primary'
          className='publish_button'
          disabled={true}
        >
          Confirmar
        </Button>
      </div>
    </div>
  );
}
