import Badge from "@mui/material/Badge";
import * as React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { toast } from "react-toastify";
import useQuery from "../../../Hooks/useQuery/useQuery";
import { getUserNotificationsTemplate } from "../../../Service/TemplateService/TemplateService";
import AedasLoading from "../../Shared/AedasLoading/AedasLoading";
import SignObjetives from "./SignObjetives/SignObjetives";
import Team from "./team/team";
import "./teamMain.css";
import ValidateObjetives from "./ValidateObjetives/ValidateObjetives";

function TeamMain(props: any) {
  const isManager = useSelector((state: any) => state.teamReducer.isManager);
  const [isLoading, setIsLoading] = React.useState(true);
  const [validation, setValidation] = React.useState(0);
  const [tabIndex, setTabIndex] = React.useState(0);
  const user = useSelector((state: any) => state.userReducer.user);
  const [sign, setSign] = React.useState(0);
  let query = useQuery();

  React.useEffect(() => {
    setTabIndex(parseInt(query.get("tab") ?? "0"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, query.get("tab")]);

  React.useEffect(() => {
    getUserNotificationsTemplate(user.employee.id)
      .then((response: any) => {
        let countSign = 0;
        let countValidation = 0;

        response.data.forEach((r: any) => {
          if (r.status === 2) {
            countValidation++;
          } else if (r.status === 3) {
            countSign++;
          }
        });
        setValidation(countValidation);
        setSign(countSign);
        //setData(aux);
        setIsLoading(false);
      })
      .catch(() => toast.error("Ha habido un problema"));
  }, [isLoading, user.employee.id]);

  if (isLoading) {
    return <AedasLoading></AedasLoading>;
  }

  if (!isManager) {
    return <Redirect to="/"></Redirect>;
  }

  return (
    <div className="px-4">
      <h1 className="titleTeams">Mi Equipo</h1>
      <Tabs
        selectedIndex={tabIndex}
        onSelect={(index: number, lastIndex: number, event: Event) => {
          setTabIndex(index);
        }}
      >
        <TabList>
          <Tab>Equipo</Tab>
          <Tab>
            <div id="pending_validation">
              <Badge badgeContent={validation} color="error">
                Validar Objetivos
              </Badge>
            </div>
          </Tab>

          <Tab id="pending_validation">
            <div id="pending_sign">
              <Badge badgeContent={sign} color="error">
                Pendiente de reunión
              </Badge>
            </div>
          </Tab>
        </TabList>

        <TabPanel>
          <div className="Team">
            <Team></Team>
          </div>
        </TabPanel>
        <TabPanel>
          <ValidateObjetives></ValidateObjetives>
        </TabPanel>
        <TabPanel>
          <SignObjetives></SignObjetives>
        </TabPanel>
      </Tabs>
    </div>
  );
}

export default TeamMain;
