import axios from "axios";

export const getExcellByUserAndYear = (userId: any, year: any) => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND +
      "/objectives/excel/" +
      userId +
      "/" +
      year,
    { responseType: "arraybuffer" }
  );
};

export const getExcellStatusByYear = (year: any) => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/objectives/excelstatus/" + year
  );
};
export const getExcelAllExcelZip = (year: any) => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/objectives/zip/" + year,
    { responseType: "arraybuffer" }
  );
};

export const generateExcels = (year: any) => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/objectives/zip/generate/" + year,
    { responseType: "arraybuffer" }
  );
};
